import Axios from "axios";
import React, { Component } from "react";
import ReactTable from "react-table";
import _ from "lodash";
import Select from 'react-select';
import { toast } from "react-toastify";
import { SETTING } from "../app-config/cofiguration";
import "react-toastify/dist/ReactToastify.css";
import { Button, Col, Container, Form, FormGroup, Modal, Row } from "react-bootstrap";
import { CustomInput } from 'reactstrap';
import BlockUi from "react-block-ui";
import Spinner from "../shared/Spinner";
import DatePicker from "react-datepicker";
//import imageCompression from 'browser-image-compression';
import { AvField, AvForm, AvInput } from "availity-reactstrap-validation";
import { ExportToCsv } from 'export-to-csv';
import {  logoutFunc, classList, filterList, capitalize, paymentTypeList, monthList, paymentModeList, sessionList, monthNames, currentSession, monthListWithValue, decryptAESObj, otherPaymentTypeList, otherPaymentDuesTypeList, invoiceFormatData, getAllList, sendDueMessage, formatToIndianCurrency, previousSession, isAdmissionInCurrentSession, addExamFees, getAdmissionSession} from "../util/helper";
import 'react-block-ui/style.css';
// import _uniqueId from 'lodash/uniqueId'
//const mime = require('mime')
import {generateInvoice} from "../util/pdfHelper";
import {AddNote} from "../AddNote";
toast.configure();

let USER = localStorage.getItem("userInformation") && JSON.parse(localStorage.getItem("userInformation"));
USER = decryptAESObj(USER)
const ROLENAME= USER && USER.userInfo && USER.userInfo.roleName

const currMonthForDues= new Date().getFinancialMonthIndex()
//const currMonthForDues= 12
const HalfYearlyMonthIndex = 6
const AnualExamMonthIndex = 12

export class Payment extends Component {
  constructor(props) {
    super(props);
    this.imageRef = React.createRef();
    this.state = {
      allPayDetail: [],
      copyOfAllPayDetail:[],
      selectedUser:{},
      paymentModal:false,
      loading:false,
      loading2:false,
      loading3:false,
      loading4: false,
      selectedFilter:'',
      totalAmount: 0,
      fineAmount: 0,
      concessionAmount: 0,
      busFee:0,
      monthlyFee:0,
      dueAmount:0,
      excessAmount:0,
      paidAmount:0,
      preDueAmount:0,
      preExcessAmount:0,
      totalPaidAmount:0,
      totalGrandAmount:0,
      vehicleList:[],
      busRouteFareList:[],
      monthlyFeeList:[],
      payOptionList:[],
      updateMonthList: monthList,
      selectedMonth:'',
      payList:[{payModeId:'Cash', amount:0}],
      feeList:[{month:'', monthlyFee:0,busRouteId:'', busFee:0}],
      selectedClass:'1 A',
      submittedDate: new Date(),
      submittedDateError:false,
      paidMonthList:[],
      phoneNumber1:'',
      phoneNumber2:'',
      viewPaymentDetail:false,
      paymentRecieverUserList:[],
      selectedSession: currentSession(),
      selectedRow:{},
      userAllInvoice:[],
      userInvoice:[],
      allStudentUserIdList:[],
      allStudentPhoneList:[],
      selectedUserId:"",
      selectedPhoneNo:'',
      deleteLoading: false,
      duesAmount: 0,
      deleteModalShow: false,
      selectedPaymentId: '',
      otherPaymentList:[{payFor:'', amount:0}],
      selectedCell: {},
      isPrint: false,
      loadingCal:false,
      totalDuesOfClass: null
    };
    
    this.handleSearch= this.handleSearch.bind(this)
    this.clearFilter = this.clearFilter.bind(this)
  }
  
  componentDidMount() {
    this.getAllList()
    this.getAllPaymentDetail()
    //this.getPaymentRecieverUser()
  }
  // Function to determine readmission charge
  shouldApplyReadmissionCharge=(user, session)=>{
    const admissionDate = (user && user.userInfo) ? user.userInfo.admissionDate: null
    if (!admissionDate) {
        return true; 
    }
    const admissionSession= getAdmissionSession(admissionDate)
    if (admissionSession==session ) {
        return false; 
    } else {
        return true
    }
  }

  async getAllPaymentDetail(){
    this.setState({
      loading:true
    })
    const url = new URL(SETTING.APP_CONSTANT.API_URL+`admin/getPaymentDetail`)
    let urlSession= ''
    let urlUserId = ''
      if(this.state.selectedClass){
          url.searchParams.set('selectedClass', this.state.selectedClass)
      }
      if(this.state.inputSearch){
          //url.searchParams.set('searchStr', this.state.inputSearch)
      }
      if(this.state.selectedUserId){
        url.searchParams.set('userId', this.state.selectedUserId)
        url.searchParams.delete('selectedPhone', this.state.selectedUserId)
        url.searchParams.delete('searchStr', this.state.inputSearch)
        url.searchParams.delete('selectedClass', this.state.selectedClass)
      }
      if(this.state.selectedPhoneNo){
        url.searchParams.set('selectedPhone', this.state.selectedPhoneNo)
        url.searchParams.delete('userId', this.state.selectedUserId)
        url.searchParams.delete('searchStr', this.state.inputSearch)
        url.searchParams.delete('selectedClass', this.state.selectedClass)
      }
      if(window.location.href.includes('payment?userId=')){
        let params = new URLSearchParams(window.location.search);
        urlUserId = params.get('userId')
        urlSession = params.get('session')
        window.history.pushState('', '', '/payment');
        this.setState({selectedUserId: urlUserId})
        url.searchParams.set('userId', urlUserId)
      }
      if(urlSession){
        url.searchParams.set('session', urlSession)
      }else{
        url.searchParams.set('session', this.state.selectedSession)
      }
   let options = SETTING.HEADER_PARAMETERS;
   options['Authorization'] = localStorage.getItem("token")
   await Axios.get(url,{headers: options})
   .then((res) => {
     this.setState({
       loading:false
     })
     if (res && res.data.success) {
       // toast["success"](res.data.message);
       this.setState({
        allPayDetail : res.data.data,
        copyOfAllPayDetail : res.data.data,
        selectedClass : (this.state.selectedUserId || urlUserId) && res.data && res.data.data && res.data.data.length>0 && res.data.data[0] && res.data.data[0].userPayDetail && res.data.data[0].userPayDetail.class
                                  ? res.data.data[0].userPayDetail.class 
                                  : this.state.selectedClass,
        selectedSession : urlSession ? urlSession : this.state.selectedSession
       },()=>this.calculateAllDues())
     } else {
       toast["error"](res.data.message);
       this.setState({
        allPayDetail: [],
       })
     }
   })
   .catch((err) =>{
     this.setState({
      loading:false
    })
    if(err && err.response && err.response.status===401){
      toast.error('Token expired.');
      logoutFunc()
    }else{
      const errorMessage= err && err.response && err.response.data && err.response.data.message?err.response.data.message :`Error while getting all payment.`
      toast["error"](errorMessage);
    }
   });
  }

   getAllList=async()=>{
    // this.setState({
    //   loading: true
    // })
    const list=  await getAllList()
    this.setState({
      vehicleList: list && list.vehicleList?list.vehicleList :[],
      busRouteFareList:  list && list.busRouteFareList?list.busRouteFareList :[],
      monthlyFeeList: list && list.monthlyFeeList?list.monthlyFeeList :[],
      payOptionList : list && list.payOptionList?list.payOptionList :[],
      allStudentUserIdList:list && list.allStudentUserIdList?list.allStudentUserIdList :[],
      allStudentPhoneList: list && list.allStudentPhoneList?list.allStudentPhoneList :[],
      paymentRecieverUserList:list && list.paymentRecieverUserList?list.paymentRecieverUserList :[]
      //loading: false
    },()=> console.log("paymentRecieverUserList", this.state.paymentRecieverUserList))
    // this.setState({
    //   loading:true
    // })
    // let options = SETTING.HEADER_PARAMETERS;
    // options['Authorization'] = localStorage.getItem("token")
    // await Axios.get(SETTING.APP_CONSTANT.API_URL+`admin/getAllList`,{headers: options})
    // .then((res) => {
    //   if (res && res.data.success) {
    //     //toast["success"](res.data.message);
    //     this.setState({
    //       vehicleList: res.data.data.vehicleList,
    //       busRouteFareList: res.data.data.busRouteFareList,
    //       monthlyFeeList: res.data.data.monthlyFeeList,
    //       payOptionList : res.data.data.payOptionList,
    //       allStudentUserIdList:res.data.data.allStudentUserIdList,
    //       allStudentPhoneList: res.data.data.allStudentPhoneList
    //     })
    //   } else {
    //     toast["error"](res.data.message);
    //   }
    // })
    // .catch((err) =>{
    //   this.setState({
    //     loading:false
    //   })
    //   if(err && err.response && err.response.status===401){
    //     toast.error('Token expired.');
    //     logoutFunc()
    //   }else{
    //     const errorMessage= err && err.response && err.response.data && err.response.data.message?err.response.data.message :`Error while getting all list.`
    //     toast["error"](errorMessage);
    //   }
    // })
   }

   async getPaymentRecieverUser(){
    this.setState({
      loading:true
    })
    let options = SETTING.HEADER_PARAMETERS;
    options['Authorization'] = localStorage.getItem("token")
    await Axios.get(SETTING.APP_CONSTANT.API_URL+`admin/getPaymentRecieverUser`,{headers: options})
    .then((res) => {
      // this.setState({
      //   loading:false
      // })
      if (res && res.data.success) {
        //toast["success"](res.data.message);
        this.setState({
          paymentRecieverUserList: res.data.data,
        })
      } else {
        toast["error"](res.data.message);
      }
    })
    .catch((err) =>{
      this.setState({
        loading:false
      })
      if(err && err.response && err.response.status===401){
        toast.error('Token expired.');
        logoutFunc()
      }else{
        const errorMessage= err && err.response && err.response.data && err.response.data.message?err.response.data.message :`Error while getting payment reciever.`
        toast["error"](errorMessage);
      }
    })
   }

   async userUpdate(phoneNumber1, phoneNumber2) {
    const id = this.state.selectedUser._id
    let dataToSend = {
      phoneNumber1: phoneNumber1,
      phoneNumber2: phoneNumber2
      //concession:values.concession
    };

    let options = SETTING.HEADER_PARAMETERS;
    options['Authorization'] = localStorage.getItem("token")
    await Axios.post(SETTING.APP_CONSTANT.API_URL+`admin/updateUser/`+id, dataToSend,{headers: options})
    .then((res) => {
      this.setState({
        loading2:false
      })
      if (res && res.data.success) {
        toast["success"](res.data.message)
        this.handleClose()
      } else {
        toast["error"](res.data.message);
      }
    })
    .catch((err) =>{
      this.setState({
        loading2:false
      })
      if(err && err.response && err.response.status===401){
        toast.error('Token expired.');
        logoutFunc()
      }else{
        const errorMessage= err && err.response && err.response.data && err.response.data.message?err.response.data.message :`Error while updating user.`
        toast["error"](errorMessage);
      }
    });
  }

   deletePaymentDetail=async()=>{
    this.setState({
      loading:true
    })
    const url = new URL(SETTING.APP_CONSTANT.API_URL+`admin/deletePayment`)

    if(this.state.selectedPaymentId){
        url.searchParams.set('paymentId', this.state.selectedPaymentId)
    }
    let options = SETTING.HEADER_PARAMETERS;
    options['Authorization'] = localStorage.getItem("token")
    await Axios.post(url,{},{headers: options})
    .then((res) => {
      this.setState({
        loading:false,
        deleteModalShow: false
      })
      if (res && res.data.success) {
        toast["success"](res.data.message);
        this.getAllPaymentDetail()
      } else {
        toast["error"](res.data.message);
      }
    })
    .catch((err) =>{
      console.log("errr", err)
      this.setState({
        loading:false,
        deleteModalShow: false
      })
      if(err && err.response && err.response.status===401){
        toast.error('Token expired.');
        //logoutFunc()
      }else{
        const errorMessage= err && err.response && err.response.data && err.response.data.message?err.response.data.message :`Error while payment delete.`
        toast["error"](errorMessage);
      }
    });
  }

  handleClose=()=>{
    this.setState({
      selectedUser:{},
      selectedMonth:'',
      editModal:false,
      loading:false,
      loading2:false,
      loading3:false,
      paymentModal: false,
      loading4: false,
      busRouteOption:false,
      totalAmount: 0,
      fineAmount: 0,
      concessionAmount: 0,
      busFee:0,
      monthlyFee:0,
      dueAmount:0,
      excessAmount:0,
      paidAmount:0,
      selectedMonth:'',
      payList:[{payModeId:'Cash', amount:0}],
      submittedDate: new Date(),
      submittedDateError:false,
      paidMonthList:[],
      //selectedSession: this.state.selectedSession? this.state.selectedSesion: currentSession(),
      viewPaymentDetail:false,
      contactModalShow: false,
      examName: "",
      exmaPayModalShow: false,
      otherPayModal: false,
      deleteModalShow: false,
      selectedPaymentId: '',
      noteModal:false,
      addNoteModalShow: false
    })
  }

  //debounecdSearch = _.debounce( () => {this.functionName()}, 1600);

  internalSearch=(searchString)=>{
    let searchStr = searchString && searchString.trim()
    searchStr = searchStr ? searchStr.toLowerCase(): searchStr
   const filterData=  this.state.copyOfAllPayDetail.filter(item=>
        (     item.sData && item.sData.userInfo && item.sData.userInfo.fullName && item.sData.userInfo.fullName.toLowerCase().includes(searchStr) 
          ||  item.sData && item.sData.userInfo && item.sData.userInfo.fatherName &&item.sData.userInfo.fatherName.toLowerCase().includes(searchStr)
          ||  item.sData && item.sData.userInfo && item.sData.userInfo.motherName && item.sData.userInfo.motherName.toLowerCase().includes(searchStr)
          ||  item.sData && item.sData.userInfo && item.sData.userInfo.userId && item.sData.userInfo.userId.toLowerCase().includes(searchStr) 
          ||  item.sData && item.sData.userInfo && item.sData.userInfo.phoneNumber1 && item.sData.userInfo.phoneNumber1.toLowerCase().includes(searchStr)
          ||  item.sData && item.sData.userInfo && item.sData.userInfo.phoneNumber2 && item.sData.userInfo.phoneNumber2.toLowerCase().includes(searchStr)
          ||  item.sData && item.sData.userInfo && item.sData.userInfo.aadharNumber && item.sData.userInfo.aadharNumber.toLowerCase().includes(searchStr)
          ||  item.sData && item.sData.userInfo && item.sData.userInfo.email && item.sData.userInfo.email.toLowerCase().includes(searchStr) 
        )
    )
    this.setState({allPayDetail: filterData})
  }
  
  handleSearch(e){
    const value= e.target.value
    this.setState({
      inputSearch:value,
      selectedUserId:"",
      selectedPhoneNo:''
    },()=> this.internalSearch(value))
  }
  
  handleClass=(e)=>{
    const value = e.target.value
    this.setState({
      selectedClass:value,
      selectedUserId:"",
      selectedPhoneNo:''
    }, ()=>this.getAllPaymentDetail())
  }
  handleSession=(e)=>{
    const value = e.target.value
    this.setState({
      selectedSession:value,
      selectedUserId:"",
      selectedPhoneNo:'',
    }, ()=>this.getAllPaymentDetail())
  }
  clearFilter=()=>{
      this.setState({
        inputSearch:"",
        selectedClass:'1 A',
        selectedSession:  currentSession(),
        submittedDateError: false,
        submittedDate: new Date(),
        selectedUserId:"",
        selectedPhoneNo:'',
      },()=>this.getAllPaymentDetail())
  }

  handleUserIdChange=(e)=>{
     this.setState({
      selectedUserId: e.value,
      selectedPhoneNo:'',
      selectedClass:'',
      inputSearch:''
     },()=> this.getAllPaymentDetail())
  }
  handlePhoneSearch=(e)=>{
    this.setState({
      selectedPhoneNo: e.value,
      selectedClass: '',
      selectedUserId:'',
      inputSearch:''
     },()=> this.getAllPaymentDetail())
  }
  handleSelectUserId=(userData, session)=>{
    // this.setState({
    //   selectedUserId: userData.userInfo.userId,
    //   selectedSession: '2023-24'
    //  },()=> this.getAllPaymentDetail())
    const redireURL =`${window.location.host.includes('localhost')?'http://':'https://'}${window.location.host}/payment?userId=${userData.userInfo.userId}&&session=${session}`
    //console.log("redireURL", redireURL)
    window.open(redireURL, "_blank")
  }
  
// calculation=(action)=>{
//   let amount= parseFloat(this.state.chargeAmount)
//   let fineAmount= this.state.fineAmount && parseFloat(this.state.fineAmount)>0?parseFloat(this.state.fineAmount):0
//   let concessionAmount= this.state.concessionAmount && parseFloat(this.state.concessionAmount)>0?parseFloat(this.state.concessionAmount):0
//     this.setState({finalAmount: amount + fineAmount - concessionAmount})
// }

handlePaymentSubmit= async(e, values, submitType='')=>{
  if(this.state.submittedDate===""){
    this.setState({submittedDateError:true}) 
    return
  } 
  if(this.state.submittedDateError) return
  this.setState({
    loading4:true
  })
  //console.log("values", values)
  if(submitType==='MONTHLY' && ((values.phoneNumber1 !==this.state.selectedUser.userInfo.phoneNumber1)|| (values.phoneNumber2 && values.phoneNumber2!==this.state.selectedUser.userInfo.phoneNumber2))){
      this.userUpdate(values.phoneNumber1, values.phoneNumber2)
  }
  let dataToSend={}
  if(submitType==='MONTHLY'){
    dataToSend={
      submitType: submitType,
      feeList: this.state.feeList,
      // fee:values.fee?values.fee:0,
      // busFee: values.busFee?values.busFee:0,
      // busRouteId : "64969713181b530028ee35e0",
      overDueAmount: values.preDueAmount,
      overExcessAmount: values.preExcessAmount,
      concession: values.concession? values.concession:0,
      dueAmount : values.dueAmount? values.dueAmount:0,
      excessAmount:values.excessAmount? values.excessAmount:0,
      fineAmount: values.fineAmount? values.fineAmount:0,
      paidAmount: values.paidAmount,
      totalGrandAmount: this.state.totalGrandAmount,
      payment: this.state.payList,
      paymentRecieverId:  values.paymentRecieverId,
      totalAmount: values.totalAmount,
      submittedDate: this.state.submittedDate, 
      insertedId: USER && USER._id,
      userId: this.state.selectedUser.userInfo.userId,
      class: this.state.selectedClass?this.state.selectedClass: this.state.selectedUser.userInfo.class,
      session: this.state.selectedSession,
      receiptNumber: values.receiptNumber,
      notes: values.notes,
      phoneNumber1: values.phoneNumber1,
      phoneNumber2: values.phoneNumber2
    }
  }
  if(submitType==='EXAM_FEE'){
    dataToSend={
      submitType: submitType,
      otherFeeList: [{
        name: this.state.examName,
        amount: Number(values.payment[0].amount)
      }],
      paidAmount: Number(values.payment[0].amount),
      payment: this.state.payList,
      paymentRecieverId:  values.paymentRecieverId,
      totalGrandAmount: Number(values.payment[0].amount),
      totalAmount: Number(values.payment[0].amount),
      submittedDate: this.state.submittedDate, 
      insertedId: USER && USER._id,
      userId: this.state.selectedUser.userInfo.userId,
      class: this.state.selectedClass?this.state.selectedClass: this.state.selectedUser.userInfo.class,
      session: this.state.selectedSession,
      receiptNumber: values.receiptNumber,
    }
  }
  if(submitType==='OTHER_PAYMENT'){
    //console.log("otherPayment", values.otherPayment)
    dataToSend={
      submitType: submitType,
      otherFeeList: values.otherPayment.map(data=> {return {name: data.payFor, amount: Number(data.amount)}}),
      paidAmount: Number(values.payment[0].amount),
      payment: this.state.payList,
      paymentRecieverId:  values.paymentRecieverId,
      totalGrandAmount: Number(values.totalOtherPayAmount),
      totalAmount: Number(values.totalOtherPayAmount),
      submittedDate: this.state.submittedDate, 
      insertedId: USER && USER._id,
      userId: this.state.selectedUser.userInfo.userId,
      class: this.state.selectedClass?this.state.selectedClass: this.state.selectedUser.userInfo.class,
      session: this.state.selectedSession,
      receiptNumber: values.receiptNumber,
      dueAmount : values.dueOtherPay? values.dueOtherPay:0,
      dueFor : values.dueOtherPaymentType? values.dueOtherPaymentType:'',
      concession: values.otherPayConcession? values.otherPayConcession:0,
      notes: values.notes,
    }
  }

    // const url= 'https://mercury-uat.phonepe.com/transact/simulator?token=c6K8TLzyIj6VS9QQ6fsa69tWzvHJEey789qXNblLIWK'
    // //const url = response.data.data.data.instrumentResponse.redirectInfo.url
    // this.setState({
    //   phonePeUrl: url,
    //   onlinePayModal: true
    // })

  //window.location.href = 'https://mercury-uat.phonepe.com/transact/simulator?token=9E7mYEWviFlagB06K1QIQg79RadeSuv6Js0eOYIDuc';
  // try {
  //   let options = SETTING.HEADER_PARAMETERS;
  //   options['Authorization'] = localStorage.getItem("token")
  //   //let fetchUrl = 'http://localhost:3010/api/admin/initiate-payment'
  //   let fetchUrl= 'http://localhost:3010/api/admin/sendMessage'
  //   const response = await Axios.post(fetchUrl, {
  //     amount:dataToSend.paidAmount,
  //     orderId:_.uniqueId(),
  //     customerId: dataToSend.userId,
  //   },{headers: options});
  //   console.log("response.data", response.data)
    // console.log("response.data.success", response.data.success)
    // console.log("response.data url", response.data.data.data.instrumentResponse.redirectInfo.url)
    // console.log("response.data url222", response.data.data.data.instrumentResponse.redirectInfo.url)
    // if (response.data.success) {
    //   //const url= 'https://mercury-uat.phonepe.com/transact/simulator?token=c6K8TLzyIj6VS9QQ6fsa69tWzvHJEey789qXNblLIWK'
    //   const url = response.data.data.data.instrumentResponse.redirectInfo.url
    //   this.setState({
    //     phonePeUrl: url,
    //     onlinePayModal: true
    //   })
    //   // window.location.href = response.data.data.data.instrumentResponse.redirectInfo.url;
    // }
  // } catch (error) {
  //   console.error('Payment initiation failed', error);
  // }

    let options = SETTING.HEADER_PARAMETERS;
    options['Authorization'] = localStorage.getItem("token")
    await Axios.post(SETTING.APP_CONSTANT.API_URL+`admin/addPayment`,dataToSend,{headers: options})
    .then((res) => {
      this.setState({
        loading4:false
      })
      if (res && res.data.success) {
        toast["success"](res.data.message)
        this.handleClose()
        this.getAllPaymentDetail();
        if(this.state.isPrint){
          this.donloadPayDetail(res.data.invoiceId)
        }
        //window.location.reload();
      } else {
        toast["error"](res.data.message);
      }
    })
    .catch((err) =>{
      this.setState({
        loading4:false
      })
      if(err && err.response && err.response.status===401){
        toast.error('Token expired.');
        logoutFunc()
      }else{
        const errorMessage= err && err.response && err.response.data && err.response.data.message?err.response.data.message :`Payment submission fail.`
        toast["error"](errorMessage);
      }
    });
 }

getTrProps = (state, rowInfo, instance) => {
  //f6e84e9e yellow
  //198ae347 blue
  if(rowInfo && rowInfo.original.userPayDetail && rowInfo.original.userPayDetail.feeFree) {
    return {
      style: {
        'backgroundColor':'#b5deaa',
      }
    }
  }
  if(rowInfo && rowInfo.original.userPayDetail && rowInfo.original.userPayDetail.busService) {
    return {
      style: {
        'backgroundColor':'#198ae347',
      }
    }
  }
  return {};
}
getCellProps = (cell, month, preDueAmount, preExcessAmount, userPayDetail) => {
  const {monthlyFeeList, updateMonthList}= this.state
  // let preDueAmount = dueAmount
  // let preExcessAmount=  excessAmount
  // const allMonthListPaid= Object.keys(userPayDetail)
  // .filter(key => userPayDetail[key]?.paidTtrue ===true)
  // .map(key => userPayDetail[key]);
    const paidMonthList= userPayDetail? Object.keys(userPayDetail).map(key=>  userPayDetail[key]?.paidStatus? key:undefined ).filter( filter=> !!filter ):[]
    //console.log("allMonthListPaid", paidMonthList)
    const enableMonthList= cell?Object.keys(cell).map(key=>  cell[key]?.payEnable? key:undefined ).filter( filter=> !!filter ):[]
    // console.log("enableMonthList", enableMonthList)
    // const monthlyFee = monthlyFeeList.find(data => data.className === cell.userInfo.class).monthlyFee
    // const busFee = cell.userInfo.busService? this.state.busRouteFareList.find(data => data._id === cell.userInfo.busRouteId).fare: 0
    const isMonthFeeFree = cell.userPayDetail && !!cell.userPayDetail.feeFree
    const monthlyFee = isMonthFeeFree ? 0 : cell[month] && cell[month].monthlyFee? cell[month].monthlyFee:0
    const busFee = cell[month] && cell[month].busFee? cell[month].busFee:0
    console.log("isMonthFeeFree",isMonthFeeFree, "monthlyFee",monthlyFee,'busFee', busFee,'preDueAmount',preDueAmount, 'preExcessAmount', preExcessAmount )
    this.setState({
        userPayDetail:cell.userPayDetail,
        paymentModal:true,
        selectedUser: cell.sData,
        selectedMonth: month,
        paidMonthList: paidMonthList,
        updateMonthList: enableMonthList.filter(item => ![...paidMonthList,month].includes(item) ),
        monthlyFee,
        busFeeActive: cell.userPayDetail.busService,
        busFee ,
        preDueAmount: preDueAmount,
        preExcessAmount: preExcessAmount,
        busRouteId: cell.userPayDetail.busService? cell.sData.userInfo.busRouteId:'',
        feeList:[{month: month, monthlyFee: monthlyFee, busRouteId: cell.userPayDetail.busService?cell.sData.userInfo.busRouteId :'', busFee: busFee}],
        totalAmount: parseInt(monthlyFee) + parseInt(busFee) +parseInt(preDueAmount) -parseInt(preExcessAmount),
      })

}
calculatePaymentAmount=()=>{
  const {payList, fineAmount, concessionAmount, feeList, preDueAmount, preExcessAmount} = this.state
  let paidAmount=0
  let allSelectedMonthFee =0
  let allSelectedBusFee =0
   payList.forEach(data=>{
      if(!!data.amount && Number(data.amount)){
        paidAmount += parseInt(data.amount)
      }
   })
   feeList.forEach(data=>{
    if(!!data.monthlyFee && Number(data.monthlyFee)){
      allSelectedMonthFee += parseInt(data.monthlyFee)
    }
    if(!!data.busFee && Number(data.busFee)){
      allSelectedBusFee += parseInt(data.busFee)
    }
   })

   console.log("allSelectedMonthFee", allSelectedMonthFee)
   console.log("allSelectedBusFee", allSelectedBusFee)
   console.log("fineAmount", fineAmount)
   console.log("concessionAmount", concessionAmount)
   console.log("preExcessAmount", preExcessAmount);
   console.log("preDueAmount", preDueAmount);
  let totalAmount = (parseInt(allSelectedMonthFee) + parseInt(allSelectedBusFee) + parseInt(fineAmount) - parseInt(concessionAmount) -parseInt(preExcessAmount) + parseInt(preDueAmount)) 
  let totalGrandAmount= (parseInt(allSelectedMonthFee) + parseInt(allSelectedBusFee) + parseInt(fineAmount) -parseInt(preExcessAmount) + parseInt(preDueAmount)) 
  let excessAmount= (paidAmount > totalAmount )? paidAmount - totalAmount :0
  let dueAmount = (totalAmount > paidAmount)?  totalAmount - paidAmount -preDueAmount :0
  this.setState({paidAmount, totalAmount, excessAmount, dueAmount, totalGrandAmount})
}
calculateOtherPaymentAmount=()=>{
  const {seletedCell, otherPaymentList, otherPayConcessionAmount, dueOtherPayAmount, payList}= this.state
  let otherPayDueAmount=0
  let totalOtherPayAmount=0
  otherPaymentList.forEach(data=>{
    if(!!data.amount && Number(data.amount)){
      totalOtherPayAmount += parseInt(data.amount)
    }
  })
  let otherPaidAmount = totalOtherPayAmount - parseInt(otherPayConcessionAmount? otherPayConcessionAmount:0) -  parseInt(dueOtherPayAmount? dueOtherPayAmount:0)
  this.setState({otherPaidAmount, totalOtherPayAmount, otherPayDueAmount, 
    payList:[
    {
      payModeId:payList && payList.length>0 && payList[0].payModeId?payList[0].payModeId:'Cash' ,
      amount:otherPaidAmount
    }]
  })

}
updatePaymentField=(rowIndex, key)=>e=>{
    let data= [...this.state.payList]
    if(key==='amount'){
      data[rowIndex][key] = e.target.value && !isNaN(e.target.value)?e.target.value:0
    }else{
      data[rowIndex][key] = e.target.value
    }
    this.setState({payList: data},
      //()=> this.calculatePaymentAmount()
      )
}
updateOtherPaymentField=(rowIndex, key)=>e=>{
  let data= [...this.state.otherPaymentList]
  if(key==='amount'){
    data[rowIndex][key] = e.target.value && !isNaN(e.target.value)? Number(e.target.value):0
  }else{
    const oldkey = data[rowIndex][key]
    data[rowIndex][key] = e.target.value
    data[rowIndex]['amount'] = oldkey === e.target.value && data[rowIndex]['amount'] && Number(data[rowIndex]['amount'])>0? Number(data[rowIndex]['amount']) : [...otherPaymentTypeList,...otherPaymentDuesTypeList].find(data=> data.value===e.target.value).amount || 0
  }
  this.setState({otherPaymentList: data},
    ()=> this.calculateOtherPaymentAmount()
    )
}
calculatePaymentField=(e, rowIndex)=>{
  let data = [...this.state.payList]
  let num = !!data[rowIndex].amount? data[rowIndex].amount: 0
  this.setState({payList: data}, ()=>this.calculatePaymentAmount())
}

onAmountChange=(e, type)=>{
  if(type === 'fine'){
    this.setState({fineAmount: parseFloat(e.target.value && !isNaN(e.target.value) ? e.target.value:0)},()=> this.calculatePaymentAmount())
  }
  if(type ==='concession'){
    this.setState({concessionAmount: parseFloat(e.target.value && !isNaN(e.target.value)? e.target.value:0)},()=> this.calculatePaymentAmount())
  }
  if(type==='otherPayConcession'){
    this.setState({otherPayConcessionAmount: parseFloat(e.target.value && !isNaN(e.target.value)? e.target.value:0)},()=> this.calculateOtherPaymentAmount())
  }
  if(type==='dueOtherPay'){
    this.setState({dueOtherPayAmount: parseFloat(e.target.value && !isNaN(e.target.value)? e.target.value:0)},()=> this.calculateOtherPaymentAmount())
  }
}

addRow=(action)=>{
  if(action==='feeList'){
    let list = this.state.feeList
    const {busFee, busRouteId, busFeeActive, monthlyFee} = this.state
    const newRow ={month: '', monthlyFee: monthlyFee, busRouteId: busFeeActive?busRouteId:'', busFee:busFeeActive?busFee:0 }
    list.push(newRow)
    this.setState({feeList:list})
  }
  if(action==='payList'){
    let list = this.state.payList
    const newRow={payModeId: '', amount: 0}
    list.push(newRow)
    this.setState({payList:list})
  }
  if(action==='otherPayList'){
    let list = this.state.otherPaymentList
    const newRow={payFor: '', amount: 0}
    list.push(newRow)
    this.setState({otherPaymentList:list})
  }
  
}
removeRow=(indexNum, actionName)=>{
  if(actionName==='payList'){
    let list = this.state.payList
    if(list.length===1) return toast['error']('This payment row is required.')
    list.splice(indexNum, 1)
    this.setState({payList:list},()=> this.calculatePaymentAmount())
  }
  if(actionName==='feeList'){
    let list = this.state.feeList
    if(list.length===1) return toast['error']('This row is required.')
    const monthName = list[indexNum].month
    let monthList = this.state.updateMonthList
    monthList.push(monthName)
    list.splice(indexNum, 1)
    this.setState({feeList:list, updateMonthList: monthList},()=> this.calculatePaymentAmount())
  }
  if(actionName==='otherPayList'){
    let list = this.state.otherPaymentList
    if(list.length===1) return toast['error']('This row is required.')
    list.splice(indexNum, 1)
    this.setState({otherPaymentList:list},()=> this.calculateOtherPaymentAmount())
  }
}
locationRedirect=(cell)=>{
  window.location.href =`${window.location.host.includes('localhost')?'http://':'https://'}${window.location.host}/allstudent?id=${cell._id}`
}
changeMonth =(e, rowIndex, key)=>{

    let data= [...this.state.feeList]
    data[rowIndex][key] = e.target.value
    this.setState({
      updateMonthList: this.state.updateMonthList.filter(data=> data!==e.target.value),
      feeList: data
    },()=>this.calculatePaymentAmount())
}

handleChange = date => {
    this.setState({
      submittedDateError: false,
      submittedDate:date
    });
};
// changePay =(e, rowIndex, key)=>{

//   let data= [...this.state.feeList]
//   data[rowIndex][key] = e.target.value
//   this.setState({
//     updateMonthList: this.state.updateMonthList.filter(data=> data!==e.target.value),
//     feeList: data
//   },()=>this.calculatePaymentAmount())
// }

amtShow=(amt)=>{
  return <span style={{color:`${amt>0?'green':''}`, fontWeight:`${amt>0?'bold':''}`}}>{amt}</span>
}

onChangePhoneNumber=actionName=>(e)=>{
    if(e.target.value && e.target.value.length===10){
        if(actionName==='phoneNumber1'){
          if((this.state.phoneNumber2 && e.target.value=== this.state.phoneNumber2) || (e.target.value===this.state.selectedUser.userInfo.phoneNumber2)){
            toast["error"]("Phone number same");
          }
          this.setState({
            phoneNumber1: e.target.value
          })
        }
        if(actionName==='phoneNumber2'){
          if((this.state.phoneNumber1 && e.target.value===this.state.phoneNumber1) || (e.target.value===this.state.selectedUser.userInfo.phoneNumber1)){
            toast["error"]("Phone number same");
          }
          this.setState({
            phoneNumber2: e.target.value
          })
        }
    }
}
checkAdmissionDate=(user, columnMonth)=>{
  let pay=true
  if(user.userInfo.admissionDate){
    let columnMonthIndex=  monthNames.indexOf(columnMonth.toLowerCase())
    const admissionDate = new Date(user.userInfo.admissionDate)
    const admissionDay = admissionDate.getDate() 
    const admissionYear = admissionDate.getFullYear()
    const admissionMonthIndex = admissionDate.getMonth()
    let admissionSession=''
    if(admissionMonthIndex>=3){
      admissionSession = `${(admissionYear).toString()}-${(admissionYear+1).toString().substring(2)}`
    }else if(admissionMonthIndex<3 ){
      admissionSession = `${(admissionYear-1).toString()}-${(admissionYear).toString().substring(2)}`
    }
    if(columnMonthIndex>=3 && currentSession()===admissionSession && (admissionMonthIndex>columnMonthIndex || (admissionMonthIndex===columnMonthIndex && admissionDay>=20 || (admissionMonthIndex<3 && admissionMonthIndex<columnMonthIndex )) )){
      pay= false
    
    }
    else if(columnMonthIndex<3 && admissionMonthIndex<3 && currentSession()===admissionSession && (admissionMonthIndex>columnMonthIndex || (admissionMonthIndex===columnMonthIndex && admissionDay>=20))){
      pay= false
    }
  }
  return pay

}
 getInvoicesByUserId=async()=>{
  const {selectedRow,selectedSession } = this.state
  this.setState({
    loading:true
  })
  let options = SETTING.HEADER_PARAMETERS;
  options['Authorization'] = localStorage.getItem("token")
  const url= new URL(SETTING.APP_CONSTANT.API_URL+`admin/getInvoicesByUserId`)
    url.searchParams.set('session', selectedSession? selectedSession:'')
    url.searchParams.set('userId', selectedRow.sData.userInfo.userId)
  

  await Axios.get(url,{headers: options})
  .then((res) => {
    if (res && res.data.success) {
      //toast["success"](res.data.message);
     let userAllInvoice= res.data.data
     //console.log("userAllInvoice",userAllInvoice)
      this.setState({
        viewPaymentDetail: true,
        selectedUser: selectedRow.sData,
        userInvoice: monthList.map(monthName=> {
          //totalPaidAmount+= cell.userPayDetail && cell.userPayDetail[monthName]? Number(cell.userPayDetail[monthName].monthlyFee)+ Number(cell.userPayDetail[monthName].busFee) :0
            return{
              ...selectedRow,
              monthName,
              userAllInvoice
            }
        }),
        totalPaidAmount: userAllInvoice.reduce((sum, curr)=> sum+ Number(curr.amount), 0),
        userAllInvoice: userAllInvoice
      })
    } else {
      toast["error"](res.data.message);
    }
  })
  .catch((err) =>{
    this.setState({
      loading:false
    })
    if(err && err.response && err.response.status===401){
      toast.error('Token expired.');
      logoutFunc()
    }else{
      const errorMessage= err && err.response && err.response.data && err.response.data.message?err.response.data.message :`Error while getting payment reciever.`
      toast["error"](errorMessage);
    }
  })
 }

 deleteTransactionByInvoiceId=async(invoiceId)=>{
  this.setState({
    deleteLoading:true
  })
  const payLoad={
      invoiceId: invoiceId,
      session: this.state.selectedSession
  }
  let options = SETTING.HEADER_PARAMETERS;
  options['Authorization'] = localStorage.getItem("token")
  const url= new URL(SETTING.APP_CONSTANT.API_URL+`admin/deleteTransaction`)

  await Axios.post(url,payLoad,{headers: options})
  .then((res) => {
    this.setState({
      deleteLoading: false
    })
    if (res && res.data.success) {
      const filterInvoice= this.state.userAllInvoice.filter(fData=> fData.invoiceId!==invoiceId)
      const userMonthlyInvoice = this.userMonthlyInvoiceReturn(filterInvoice)
      this.setState({
        userAllInvoice: filterInvoice,
        userMonthlyInvoice: userMonthlyInvoice,
        userOtherInvoice: this.userOtherInvoiceReturn(filterInvoice),
        pageSizeOfViewPayment:  this.pageSizeCount(userMonthlyInvoice)
      })
       this.getAllPaymentDetail()
      toast["success"](res.data.message);
    } else {
      toast["error"](res.data.message);
    }
  })
  .catch((err) =>{
    this.setState({
      deleteLoading:false
    })
    if(err && err.response && err.response.status===401){
      toast.error('Token expired.');
      logoutFunc()
    }else{
      const errorMessage= err && err.response && err.response.data && err.response.data.message?err.response.data.message :`Error while getting payment reciever.`
      toast["error"](errorMessage);
    }
  })
 }

 pageSizeCount= (userInvoiceData)=>{
  let mCount = 0
  let rowCount = 0
  for (const it of userInvoiceData) {
    if(it.invoiceInfo.feeList && it.invoiceInfo.feeList.length){
      mCount += it.invoiceInfo.feeList.length
      rowCount ++
    }
  } 

  return 12- mCount + rowCount
 }

 userMonthlyInvoiceReturn=(allInvoice)=>{
  return allInvoice.map(item => {
    const { invoiceInfo: { otherFeeList, ...restInvoiceInfo }, ...restItem } = item
    if(item.invoiceInfo.feeList){
      return {
        ...restItem,
        invoiceInfo: {
            ...restInvoiceInfo
        }
      }
    }
  }).filter(notUndefined => notUndefined !== undefined)
 }
 userOtherInvoiceReturn=(allInvoice)=>{
      return allInvoice.map(item => {
      const { invoiceInfo: { feeList, ...restInvoiceInfo }, ...restItem } = item
      if(item.invoiceInfo.otherFeeList){
        return {
            ...restItem,
            invoiceInfo: {
                ...restInvoiceInfo
            }
        }
      }
    }).filter(notUndefined => notUndefined !== undefined)
 }

actionTogle=async(cell, action)=>{
    if(action ==='VeiwDetail'){
      const userMonthlyInvoice = this.userMonthlyInvoiceReturn(cell.userInvoiceDetail)
      this.setState({
        viewPaymentDetail: true,
        selectedUser: cell.sData,
        userAllInvoice: cell.userInvoiceDetail,
        userMonthlyInvoice: userMonthlyInvoice,
        userOtherInvoice: this.userOtherInvoiceReturn(cell.userInvoiceDetail),
        pageSizeOfViewPayment: this.pageSizeCount(userMonthlyInvoice)
      })
    }
    if(action ==='contact'){
      const dues = (this.state.selectedSession && this.state.selectedSession===currentSession())? this.getDues(cell): this.getOldSessionDues(cell)
      this.setState({
        selectedUser: cell.sData,
        contactModalShow: true,
        duesAmount: dues
      })
    }
    if(action ==='deletePayment'){
      this.setState({
        selectedPaymentId: cell.userPayDetail && cell.userPayDetail._id,
        deleteModalShow: true,
      })
    }
    if(action ==='otherPayment'){
      let oldOtherPaymentList= []
      let dueAmt=0
      const oldOtherDue= cell.userPayDetail && cell.userPayDetail.otherDue
      if(oldOtherDue){
        for(const key in oldOtherDue) {
          if(oldOtherDue[key]&& Number(oldOtherDue[key])>0){
            dueAmt +=  Number(oldOtherDue[key])
            oldOtherPaymentList = [...oldOtherPaymentList,{payFor:key, amount:Number(oldOtherDue[key])}] 
          }
        }
      }
      if( Number(cell.userPayDetail.dueAmount) >0 && cell.userPayDetail['march'] && cell.userPayDetail['march'].paidStatus){
          dueAmt += Number(cell.userPayDetail.dueAmount)
          oldOtherPaymentList= [...oldOtherPaymentList, {payFor:'dueMarch_Month_Bus', amount: Number(cell.userPayDetail.dueAmount)}]
      }
      this.setState({
        selectedCell: cell,
        selectedUser: cell.sData,
        otherPaymentList:dueAmt>0?oldOtherPaymentList:[{payFor:'', amount:0}],
        otherPayModal: true,
        dueOtherPayAmount:0,
        otherPayConcessionAmount:0,
        totalOtherPayAmount:dueAmt,
        payList:[{payModeId:'Cash', amount:dueAmt}], 
      })
    }
    if(action ==='reAdmission'){
      const upperClassName = classList.filter(data=> Number(data.split(' ')[0])>0)
      const reAdmissClassType = upperClassName.includes(cell.sData.userInfo.class)?'reAdmissionUpper':'reAdmissionUnder'
      // console.log("isUpperClass",isUpperClass)
      const reAdmissionList = otherPaymentTypeList.find(data=> data.value===reAdmissClassType)
      console.log("reAdmissionList", reAdmissionList)
      this.setState({
        selectedCell: cell,
        selectedUser: cell.sData,
        otherPaymentList:[{payFor:reAdmissionList.value, amount:reAdmissionList.amount}],
        otherPayModal: true,
        dueOtherPayAmount:0,
        otherPayConcessionAmount:0,
        totalOtherPayAmount:reAdmissionList.amount,
        payList:[{payModeId:'Cash', amount:reAdmissionList.amount}], 
      })
    }
    if(action==='dueMessage'){
      let userId = cell.sData.userInfo.userId
      let amount= Number(this.getDues(cell)) + Number(cell.prevYearAmtDue)
      const number1= cell.sData && cell.sData &&  cell.sData.userInfo && cell.sData.userInfo.phoneNumber1
      const number2= cell.sData && cell.sData &&  cell.sData.userInfo && cell.sData.userInfo.phoneNumber1
      if (!!number1) {
        const messageStatus = await sendDueMessage(number1, userId, amount)
        console.log("messageStatus", messageStatus)
         if(messageStatus && messageStatus.status){
           toast['success'](messageStatus.message)
         }else{
          toast["error"](messageStatus && messageStatus.message?messageStatus.message:'Message not sent')
         }
      }
      if (!!number2 && number1!==number2) {
        const messageStatus = await sendDueMessage(number2, userId, amount)
        if(messageStatus && messageStatus.status){
          toast['success'](messageStatus.message)
        }else{
          toast["error"](messageStatus && messageStatus.message?messageStatus.message:'Message not sent')
        }
      }
    }
    if(action==='addNoteAction'){
      this.setState({
        selectedUser: cell.sData,
        addNoteModalShow : true
      })
    }
}
noteDetail=(cell)=>{
  this.setState({
    noteModal: true,
    noteData: cell.invoiceInfo.notes
  })
}
handleCloseNoteModal=()=>{
  this.setState({
    noteModal: false,
    noteData: ''
  })
}
handleCloseOnlinePayModal=()=>{
  this.setState({
    phonePeUrl: '',
    onlinePayModal: false
  })
}

copyToClipBoard(text) {
  text = text.replaceAll('-','')
  const heloxPin = document.createElement('textarea')
  heloxPin.value = text;
  document.body.appendChild(heloxPin);
  heloxPin.select();
  document.execCommand('copy');
  document.body.removeChild(heloxPin)
  toast.success('Phone Number Copied');

}

donloadPayDetail=async(invoiceId)=>{
  //console.log("cellcellcellcell", invoiceId);
  let options = SETTING.HEADER_PARAMETERS;
  options['Authorization'] = localStorage.getItem("token")
  await Axios.get(SETTING.APP_CONSTANT.API_URL+`admin/getAllInvoice?invoiceId=`+invoiceId,{headers: options})
  .then((res) => {
    this.setState({
      loading4:false
    })
    if (res && res.data.success) {
      let invoiceData=res.data.data[0]
      const isPrint=this.state.isPrint
      generateInvoice(invoiceFormatData(invoiceData),isPrint)
    } else {
      toast["error"](res.data.message);
    }
  })
  .catch((err) =>{
    this.setState({
      loading4:false
    })
    if(err && err.response && err.response.status===401){
      toast.error('Token expired.');
      logoutFunc()
    }else{
      const errorMessage= err && err.response && err.response.data && err.response.data.message?err.response.data.message :`Invoice detail not found.`
      toast["error"](errorMessage);
    }
  });
}
exportDuesCSV =()=>{
  const options = { 
    fieldSeparator: ',',
    quoteStrings: '"',
    decimalSeparator: '.',
    showLabels: true, 
    showTitle: true,
    title: `${this.state.selectedClass ?'Class'+ this.state.selectedClass:''}(${new Date().getFullYear()} - ${(new Date().getFullYear()+1).toString().slice(2)})Dues-${(monthNames[new Date().getMonth()]).toUpperCase()}`,
    useTextFile: false,
    filename:`${this.state.selectedClass ?( this.state.selectedClass).split(" ").join("_")+'_':''}Dues_${new Date().getFullYear()}_${(new Date().getFullYear()+1).toString().slice(2)}_${monthNames[new Date().getMonth()]}`,
    useBom: true,
   // useKeysAsHeaders: true,
   headers: ['S.No.','BMMSID','Student Name','Father Name', ...monthList.map(name=> 
     name==='january'?'JANU'
    :name==='february'?'FEBR'
    :name==='august'?'AUGU'
    :name==='september'?'SEPT'
    :name==='october'?'OCTO'
    :name==='november'?'NOVE'
    :name==='december'?'DECE'
    :name.toUpperCase()
    ),
    'Total Dues', 'Phone Number 1', 'Phone Number 2'] 
  };

  let csvData = this.state.copyOfAllPayDetail.map((data, index)=>  {
    const dues = (this.state.selectedSession && this.state.selectedSession===currentSession())? this.getDues(data) + Number(data.prevYearAmtDue? data.prevYearAmtDue:0): this.getOldSessionDues(data)
    const newData={
        sno:(index+1)+'\t',
        bmmsId: data.sData.userInfo.userId+'\t',
        studentName: data.sData.userInfo.fullName,
        fatherName: data.sData.userInfo.fatherName,
        april:data['april'].payEnable? data['april'].amt:'###',
        may: data['may'].payEnable? data['may'].amt:'###',
        june:data['june'].payEnable? data['june'].amt:'###',
        july:data['july'].payEnable? data['july'].amt:'###',
        august:data['august'].payEnable? data['august'].amt:'###',
        september:data['september'].payEnable? data['september'].amt:'###',
        october:data['october'].payEnable? data['october'].amt:'###',
        november:data['november'].payEnable? data['november'].amt:'###',
        december:data['december'].payEnable? data['december'].amt:'###',
        january:data['january'].payEnable? data['january'].amt:'###',
        february:data['february'].payEnable? data['february'].amt:'###',
        march:data['march'].payEnable? data['march'].amt:'###',
        dues: dues,
        phoneNumber1: data.sData.userInfo.phoneNumber1,
        phoneNumber2: data.sData.userInfo.phoneNumber2?data.sData.userInfo.phoneNumber2:'',
        //bmmsId:`="${data.userInfo.userId}"`,
        //bmmsId:data.userInfo.userId+"\t",
        //classd:data.userInfo.class+"\t",
        //fullName:data.userInfo.fullName,
    }
    return newData
   })
   //console.log("csvDatacsvData",csvData)
   
  const csvExporter = new ExportToCsv(options);
  csvExporter.generateCsv(csvData);
}
getDues=(cellData)=>{
  //console.log("cellDatacellData", cellData)
  //const userPayDetail = cellData.userPayDetail
  let paidAmt = 0
  let dueAmt=0
  const preDueAmount = cellData.preDueAmount
  const preExcessAmount = cellData.preExcessAmount
  const feeData= this.state.monthlyFeeList.find(data=> data.className===this.state.selectedClass)
  const isOtherPay = (cellData.userPayDetail && cellData.userPayDetail.other && cellData.userPayDetail.other.length > 0)? true: false
  const isMonthFeeFree = !!cellData.userPayDetail.feeFree 
  const isAnnualExamFeeNotPaid = isOtherPay? !cellData.userPayDetail.other.some(data => data.name === 'ANNUAL EXAM') : true
  const isHalfExamFeeNotPaid = isOtherPay? !cellData.userPayDetail.other.some(data => data.name === 'HALF YEARLY EXAM') : true
  if (currMonthForDues >= 5) { // After august
    if(cellData.sData.userInfo.admissionDate){
      const admissionDate = new Date(cellData.sData.userInfo.admissionDate);
      const admissionMonthIndex = admissionDate.getFinancialMonthIndex(); // APRIL 1 & MARCH 12
      const admissionSession = getAdmissionSession(cellData.sData.userInfo.admissionDate);
      const isCurrentSession = isAdmissionInCurrentSession(admissionSession, currentSession());
      if(isCurrentSession){
          if(admissionMonthIndex<HalfYearlyMonthIndex && isHalfExamFeeNotPaid){
            dueAmt += addExamFees(feeData, false, true)
          }
          if(admissionMonthIndex<AnualExamMonthIndex && (currMonthForDues === 11 || currMonthForDues === 12) && isAnnualExamFeeNotPaid){
            dueAmt += addExamFees(feeData, true, false)
          }
      }else{
        if(isHalfExamFeeNotPaid){
          dueAmt += addExamFees(feeData, false, true)
        }
        if((currMonthForDues === 11 || currMonthForDues === 12) && isAnnualExamFeeNotPaid){
          dueAmt += addExamFees(feeData, true, false)
        }
      }
    }else{
      if(isHalfExamFeeNotPaid){
        dueAmt += addExamFees(feeData, false, true)
      }
      if((currMonthForDues === 11 || currMonthForDues === 12) && isAnnualExamFeeNotPaid){
        dueAmt += addExamFees(feeData, true, false)
      }
    }
  }

  const isReadmissionCharge = this.shouldApplyReadmissionCharge(cellData.sData, currentSession());
  if (isReadmissionCharge) {
    const reAdmUp= 'reAdmissionUpper'
    const reAdmUn= 'reAdmissionUnder'
    const reAdmissionData=  cellData.userPayDetail && cellData.userPayDetail.other && cellData.userPayDetail.other.length>0 && cellData.userPayDetail.other.find(data=> (data.name==reAdmUp|| data.name==reAdmUn))
    if(!reAdmissionData){
      const upperClassName = classList.filter(data=> Number(data.split(' ')[0])>0)
      const reAdmissClassType = upperClassName.includes(cellData.sData.userInfo.class)?reAdmUp:reAdmUn
      const reAdmissionList = otherPaymentTypeList.find(data=> data.value===reAdmissClassType)
      dueAmt += Number(reAdmissionList.amount)
    }
  }

  if(cellData.userPayDetail && cellData.userPayDetail.otherDue){
    for(const key in cellData.userPayDetail.otherDue) {
      dueAmt += cellData.userPayDetail.otherDue[key]? Number(cellData.userPayDetail.otherDue[key]):0;
    }
  }
 
  monthListWithValue.map(mData=>{
    if(cellData[mData.name].payEnable){
      if(cellData[mData.name].paidDone){
        paidAmt+=cellData[mData.name].amt?Number(cellData[mData.name].amt):0
      }else{
        if(mData.value<=currMonthForDues ){
          dueAmt+= (isMonthFeeFree ? 0 : Number(cellData[mData.name].monthlyFee))+ Number(cellData[mData.name].busFee)
        }
      }
    }
  })

  // console.log("paidAmt",paidAmt  )
  // console.log("dueAmt",dueAmt )
  // console.log("preDueAmount",preDueAmount )
  // console.log("preExcessAmount",preExcessAmount  )

  return dueAmt + Number(preDueAmount) -Number(preExcessAmount)
}

getOldSessionDues=(cellData)=>{
  //console.log("cellDatacellData", cellData)
  let paidAmt = 0
  let dueAmt=0
  const preDueAmount = cellData.preDueAmount
  const preExcessAmount = cellData.preExcessAmount
    const feeData= this.state.monthlyFeeList.find(data=> data.className===this.state.selectedClass)
    const isOtherPay= cellData.userPayDetail && cellData.userPayDetail.other && cellData.userPayDetail.other.length > 0? true:false
    const isAnnualExamFeeNotPaid = isOtherPay? !cellData.userPayDetail.other.some(data => data.name === 'ANNUAL EXAM') : true
    const isHalfExamFeeNotPaid = isOtherPay? !cellData.userPayDetail.other.some(data => data.name === 'HALF YEARLY EXAM') : true
    if(cellData.sData.userInfo.admissionDate){
      const admissionDate = new Date(cellData.sData.userInfo.admissionDate);
      const admissionMonthIndex = admissionDate.getFinancialMonthIndex();
      const admissionSession = getAdmissionSession(cellData.sData.userInfo.admissionDate);
      const isCurrentSession = isAdmissionInCurrentSession(admissionSession, this.state.selectedSession);
      if(isCurrentSession){
        if(admissionMonthIndex<HalfYearlyMonthIndex && isHalfExamFeeNotPaid){
          dueAmt += addExamFees(feeData, false, true)
        }
        if(admissionMonthIndex<AnualExamMonthIndex && isAnnualExamFeeNotPaid){
          dueAmt += addExamFees(feeData, true, false)
        }
      }else{
        if(isHalfExamFeeNotPaid){
          dueAmt += addExamFees(feeData, false, true)
        }
        if(isAnnualExamFeeNotPaid){
          dueAmt += addExamFees(feeData, true, false)
        }
      }
    }else{
      if(isHalfExamFeeNotPaid){
        dueAmt += addExamFees(feeData, false, true)
      }
      if(isAnnualExamFeeNotPaid){
        dueAmt += addExamFees(feeData, true, false)
      }
    }

    if(cellData.userPayDetail && cellData.userPayDetail.otherDue){
      for(const key in cellData.userPayDetail.otherDue) {
        dueAmt += cellData.userPayDetail.otherDue[key]? Number(cellData.userPayDetail.otherDue[key]):0;
      }
    }
 
  monthListWithValue.map(mData=>{
    if(cellData[mData.name].payEnable){
      if(cellData[mData.name].paidDone){
        paidAmt+=cellData[mData.name].amt?Number(cellData[mData.name].amt):0
      }else{
        if(mData.value<=12 ){
          dueAmt+=Number(cellData[mData.name].monthlyFee)+ Number(cellData[mData.name].busFee)
        }
      }
    }
  })

  // console.log("paidAmt",paidAmt  )
  // console.log("dueAmt",dueAmt )
  // console.log("preDueAmount",preDueAmount )
  // console.log("preExcessAmount",preExcessAmount  )

  return dueAmt + Number(preDueAmount) -Number(preExcessAmount)
}

examPayModel=(cellData, examName)=>{
console.log("his.state.monthlyFeeList", this.state.monthlyFeeList)
console.log("this.state.selectedClass", this.state.selectedClass)
  const feeData= this.state.monthlyFeeList.find(data=> data.className===this.state.selectedClass)
  console.log("feeData", feeData)
  this.setState({
    examName: examName,
    selectedUser: cellData.sData,
    exmaPayModalShow: true,
    payList:[
      {
        // payModeId:'Cash',
        amount:feeData && feeData.examFee? Number(feeData.examFee):0
      }]
  })
}

closeExamPayModal=()=>{
  this.setState({
    examName: "",
    selectedUser: {},
    exmaPayModalShow: false,
    payList:[{payModeId:'Cash', amount:0}],
  })
}
closeOtherPayModal=()=>{
  this.setState({
    selectedUser: {},
    selectedCell: {},
    otherPayModal: false,
    payList:[{payModeId:'Cash', amount:0}],
  })
}

calculteWidth=()=>{
  return this.state.selectedSession && this.state.selectedSession===currentSession()?58: 80
}

calculateAllDues=()=>{
  let dueAmount=0
  this.state.copyOfAllPayDetail.map((data, index)=>  {
    const dues = (this.state.selectedSession && this.state.selectedSession===currentSession())? this.getDues(data) + Number(data.prevYearAmtDue? data.prevYearAmtDue:0): this.getOldSessionDues(data)
    dueAmount+=dues
  })
  this.setState({
    totalDuesOfClass: dueAmount
  })
}
data_from_note_compo=(data)=>{
  if(data && data.success===true){
      this.getAllPaymentDetail()
      toast["success"](data.message)
  }else{
    toast["error"](data.message? data.message:"Notes not added")
  }
}

  render() {
    const {
       selectedUser, 
       busRouteFareList, 
       payList, 
       excessAmount, 
       dueAmount, 
       paidAmount, 
       totalAmount,
       totalGrandAmount,
       preDueAmount,
       preExcessAmount,
       submittedDateError,
       payOptionList,
       paymentRecieverUserList,
       allStudentUserIdList,
       selectedUserId,
       otherPaymentList,
       totalOtherPayAmount,
       dueOtherPayAmount
      } = this.state

    const columns = [
      {
        Header: "Full Name",
        accessor: "userInfo.fullName",
        width: 150,
        Cell: cell  => (
          <span style={{cursor:'pointer'}} onClick={()=> this.locationRedirect(cell.original.sData) }>
            {`${cell.original.sData.userInfo.fullName} (${cell.original.sData.userInfo.userId})`}
          </span>
        )
      },
      ...monthList.map((monthName)=>{
          return{
            Header:  () => <div style={{ textAlign: "center",textTransform: 'uppercase' }}>{monthName}</div>,
            //accessor: "userInfo.fullName",
            width: 44,
            Cell: cell  => {
              if(cell.original[monthName].payEnable){
                  const userPayDetail = cell.original.userPayDetail
                  const amt = cell.original[monthName].amt
                  const preDueAmount = cell.original.preDueAmount
                  const preExcessAmount = cell.original.preExcessAmount
                  
                return (
                  <div style={{cursor:`${!(amt>0)?'pointer':''}`,textAlign: "center" }} onClick={!(amt>0)?()=>this.getCellProps(cell.original, monthName, preDueAmount, preExcessAmount, userPayDetail):undefined}>
                  {this.amtShow(amt)}
                  </div>
                )
              }else{
                return (
                  <div style={{textAlign: "center" , color:'GrayText'}} >
                      ###
                  </div>
                )
              }
            }
          }
      }),
      {
        Header: ()=><>
        <span>Em1</span>
        <br/>
        <span>Fee</span>
        </>,
        //accessor: "userInfo.fullName",
        width: 42,
        Cell: cell  => {
          const examName= 'HALF YEARLY EXAM'
          const examAmtData= cell.original && cell.original.userPayDetail && cell.original.userPayDetail.other && cell.original.userPayDetail.other.length>0 && cell.original.userPayDetail.other.find(data=> data.name===examName)
          const examAmt=  examAmtData && examAmtData.amount? Number(examAmtData.amount):0
          return (
            <div style={{cursor:`${!(examAmt>0)?'pointer':''}`,textAlign: "center" }} onClick={!(examAmt>0)?()=>this.examPayModel(cell.original, examName):undefined}>
            {examAmt}
            </div>
          )
        }
      },
      {
        Header: ()=><>
        <span>Em2</span>
        <br/>
        <span>Fee</span>
        </>,
        //accessor: "userInfo.fullName",
        width: 42,
        Cell: cell  => {
          const examName= 'ANNUAL EXAM'
          const examAmtData= cell.original && cell.original.userPayDetail && cell.original.userPayDetail.other && cell.original.userPayDetail.other.length>0 && cell.original.userPayDetail.other.find(data=> data.name===examName)
          const examAmt=  examAmtData && examAmtData.amount? Number(examAmtData.amount):0
          return (
            <div style={{cursor:`${!(examAmt>0)?'pointer':''}`,textAlign: "center" }} onClick={!(examAmt>0)?()=>this.examPayModel(cell.original, examName):undefined}>
            {examAmt}
            </div>
          )
        }
      },
      {
        Header: ()=><>
        <span>Re-</span>
        <br/>
        <span>Adm</span>
        </>,
        //accessor: "userInfo.fullName",
        width: 40,
        Cell: cell  => {
          const isReadmissionCharge = this.shouldApplyReadmissionCharge(cell.original.sData, currentSession());
          const reAdmUp= 'reAdmissionUpper'
          const reAdmUn= 'reAdmissionUnder'
          const reAdmissionData= cell.original && cell.original.userPayDetail && cell.original.userPayDetail.other && cell.original.userPayDetail.other.length>0 && cell.original.userPayDetail.other.find(data=> (data.name==reAdmUp || data.name==reAdmUn))
          const reAdmissionAmt=  reAdmissionData && reAdmissionData.amount? Number(reAdmissionData.amount):0
            if(isReadmissionCharge){
              return (
              <div 
                style={{cursor:`${!(reAdmissionAmt>0)?'pointer':''}`,textAlign: "center" }} onClick={!(reAdmissionAmt>0)?()=>this.actionTogle(cell.original, 'reAdmission'):undefined}
              >
              {reAdmissionAmt}
              </div>
              )
            }else{
              return  (<div style={{textAlign: "center" , color:'GrayText'}} >### </div>)
            }
        }
      },
      {
        Header: <>
        <span>Back</span>
        <br/>
        <span>Dues</span>
        </>,
        //accessor: "userInfo.fullName",
        width: 45,
        Cell: cell  => {
          return <span style={cell.original.preDueAmount?{color:"red", fontWeight:"bold"}:{}}>+{cell.original.preDueAmount}</span>
        }
      },
      {
        Header: ()=><>
        <span>Extra</span>
        <br/>
        <span>Amt</span>
        </>,
        //accessor: "userInfo.fullName",
        width: 45,
        Cell: cell  => {
           
           return  `-${cell.original.preExcessAmount}`
        }
      },
      {
        Header: ()=><>
        <span>{(this.state.selectedSession && this.state.selectedSession===currentSession())?'Dues': 'March Dues'}</span>
        <br/>
        <span style={{textTransform:'capitalize'}}>{(this.state.selectedSession && this.state.selectedSession===currentSession())? monthListWithValue.find(data=> data.value===currMonthForDues).name:`(${this.state.selectedSession})`}</span>
        </>,
        //accessor: "userInfo.fullName",
        width:this.calculteWidth(), 
        Cell: cell  => {
           const dues = (this.state.selectedSession && this.state.selectedSession===currentSession())? this.getDues(cell.original): this.getOldSessionDues(cell.original)
           return <span style={{fontWeight:'700'}}>{dues}/-</span> 
        }
      },
      {
        Header: ()=><>
        <span>Prev</span>
        <br/>
        <span>Year</span>
        </>,
        //accessor: "userInfo.fullName",
        width: 50,
        show: (this.state.selectedSession && this.state.selectedSession===currentSession())? true: false,
        Cell: cell  => {
          //()=> window.open("someLink", "_blank")
           return  <span style={{cursor:'pointer'}} onClick={()=>this.handleSelectUserId(cell.original.sData, previousSession())}>{cell.original.prevYearAmtDue}/-</span>
        }
      },
      {
        Header: "Action",
        accessor: "action",
        width: 180,
        //show:USER && (USER.userInfo.roleName==='TOPADMIN' || USER.userInfo.roleName==='ADMIN')? true:false,
        Cell:cell=>{
          return(
              <Row style={{marginLeft:"0.1rem", marginTop:"0px"}}>
                  {USER && USER.userInfo.roleName==='TOPADMIN' &&
                    <button title='Delete' id={'delete'}
                    style={{color: "red"}}
                    className="no-decoration-button" 
                    onClick={e=>this.actionTogle(cell.original, 'deletePayment')}>
                      <i className=" mdi mdi-delete mdi-18px"></i>
                    </button>
                  }
                   <button title='Other Payment' id={'OtherPayment'}
                    style={{color: "green"}}
                    className="no-decoration-button" 
                    onClick={e=>this.actionTogle(cell.original, 'otherPayment')}>
                      <i className="mdi mdi-cash-multiple mdi-18px"></i>
                  </button> 
                  {"  "}
                  <button title='Detail' id={'VeiwDetail'}
                     style={{color: "blue"}}
                     className="no-decoration-button" 
                    onClick={e=>this.actionTogle(cell.original, 'VeiwDetail')}
                    >
                      <i className="mdi mdi-receipt mdi-18px"></i>
                  </button>
                  <button title='Contact' id={'contact'}
                     style={{color: "green"}}
                     className="no-decoration-button" 
                    onClick={e=>this.actionTogle(cell.original, 'contact')}
                    >
                      <i className="mdi mdi-phone mdi-18px"></i>
                  </button>
                   <button title='Dues Message' id={'duesMessage'}
                   style={{color: "red"}}
                   className="no-decoration-button" 
                    onClick={(e)=>this.actionTogle(cell.original, 'dueMessage')}
                    >
                      <i className="mdi mdi-near-me mdi-18px"></i>
                  </button>
                  {/* <button title='Dues Message' id={'duesMessage'}
                   style={{color: "red"}}
                   className="no-decoration-button" 
                    onClick={(e)=>this.actionTogle(cell.original, 'dueMessage')}
                    >
                      <i className="mdi mdi-near-me mdi-18px"></i>
                  </button> */}
                  <button title='Add Note' id={'addNote'}
                     style={{color: cell.original.sData && cell.original.sData.userInfo && cell.original.sData.userInfo.notes && cell.original.sData.userInfo.notes.length>0?"blue":'grey'}}
                     className="no-decoration-button" 
                    //className="mb-2 badge" 
                    onClick={e=>this.actionTogle(cell.original, 'addNoteAction')}
                    >
                      <i className="mdi mdi mdi-note-text mdi-18px"></i>
                  </button>
              </Row>
          )
        }
      }
    ];
    

    const monthlyInvoiceColumn=[
      {
        Header: 'Month Name',
        accessor: 'Month Name',
        width:90,
        Cell: (cell)=>{
          if(cell.original.invoiceInfo.feeList){
            return cell.original.invoiceInfo.feeList.map((data, index)=>{
              return<div className="py-1">
                {data.month.toUpperCase()}
                {(index!== cell.original.invoiceInfo.feeList.length-1)?<hr className="mt-1 mb-0"></hr>:''}
              </div>
            })
          }else{
            return <div className="py-1"></div>
          }
        }
      }, 
      {
        Header: 'Month Fee',
        accessor: 'Month Fee',
        width:75,
        Cell: (cell)=>{
          if(cell.original.invoiceInfo.feeList){
            return  cell.original.invoiceInfo.feeList.map((data, index)=>{
                return<div className="py-1">
                  {data.monthlyFee}
                  {(index!==  cell.original.invoiceInfo.feeList.length-1)?<hr className="mt-1 mb-0"></hr>:''}
                </div>
            })
          }else{
            return <div className="py-1"></div>
          }  
        }
      }, 
      {
        Header: 'Bus Fee',
        accessor: 'Bus Fee',
        width:60,
        Cell: (cell)=>{
          if(cell.original.invoiceInfo.feeList){
            return cell.original.invoiceInfo.feeList.map((data, index)=>{
                return<div className="py-1">
                  {data.busFee}
                  {(index!== cell.original.invoiceInfo.feeList.length-1)?<hr className="mt-1 mb-0"></hr>:''}
                </div>
            })
          }else{
            return <div className="py-1"></div>
          }
        }
      },
      {
        Header: 'Invoice No',
        accessor: 'invoiceId',
        width:75,
      },
      {
        Header: 'Rec.No.',
        accessor: 'invoiceInfo.receiptNumber',
        width:58,
      },
      {
        Header: "Date",
        accessor: "date",
        width: 72,
        Cell: (cell)=>{
         return cell.original.invoiceInfo.submittedDate?  new Date(cell.original.invoiceInfo.submittedDate).toLocaleDateString("en-GB"): ''
        }
      },
      {
        Header: "Total Amt",
        accessor: "Total Amount",
        width: 70,
        Cell: (cell)=>{
         return <div className="text-center">{cell.original.invoiceInfo.feeList && cell.original.invoiceInfo.feeList.reduce((sum, curr)=> sum + Number(curr.monthlyFee) + Number(curr.busFee), 0 )}</div>
        }
      },
      {
        Header: "Concession",
        accessor: "concession",
        width: 78,
        Cell: (cell)=>{
         return <div className="text-center">-{cell.original.invoiceInfo.concession}</div>
        }
      },
      {
        Header: "Dues",
        accessor: "invoiceInfo.dueAmount",
        width: 50,
        Cell: (cell)=>{
          return <div className="text-center">-{cell.original.invoiceInfo.dueAmount}</div>
         }
      },
      {
        Header: "Ex Amt", //Excess Amount
        accessor: "invoiceInfo.excessAmount",
        width: 60,
        Cell: (cell)=>{
         return <div className="text-center">+{cell.original.invoiceInfo.excessAmount}</div>
        }
      },
      {
        Header: "Fine Amt",
        accessor: "invoiceInfo.excessAmount",
        width: 65,
        Cell: (cell)=>{
         return <div className="text-center">{cell.original.invoiceInfo.fineAmount}</div>
        }
      },
      {
        Header: "Paid Amt",
        accessor: "Paid Amount",
        width: 80,
        Cell: (cell)=>{
         return <span style={{fontWeight:'bold'}}>{cell.original.amount}/-</span>
        }
      },
      {
        Header: "Reciever Name",
        accessor: "Reciever Name",
        width: 140,
        Cell: (cell)=>{
          const paymentRecieverId = cell.original.invoiceInfo.paymentRecieverId
          const paymentRecieverData = paymentRecieverId? paymentRecieverUserList.find(it=> it._id.toString()===paymentRecieverId): {}
          if (paymentRecieverId){
            return paymentRecieverData && paymentRecieverData.userInfo? paymentRecieverData.userInfo.fullName: 'Not Found' 
          }else{
            return ''
          }
        }
      },
      {
        Header: "Action",
        //accessor: "userInfo.class",
        //width: 50,
        Cell: (cell)=>{
          //console.log("cell.original[monthName].payEnable",monthName, !!cell.original.userPayDetail && !!cell.original.userPayDetail[monthName])
          return(
            <Row style={{marginLeft:"0.1rem", marginTop:"0px"}}>
                  {cell.original.invoiceId &&
                    (<a title='Detail' id={'detail'}
                    style={{paddingRight: '0px',
                    paddingTop: '0px',
                    paddingBottom: '0px',cursor:'pointer'}}
                    className="mb-0 badge" 
                    onClick={e=>this.donloadPayDetail(cell.original.invoiceId)}
                    >
                      <i className="mdi mdi-download mdi-18px"></i>
                  </a>)
                  }
                  {USER && USER.userInfo && USER.userInfo.roleName && USER.userInfo.roleName==='TOPADMIN' &&
                  <a title='Delete' id={'deleteTx'}
                    style={{paddingRight: '0px',
                    paddingTop: '0px',
                    paddingBottom: '0px',cursor:'pointer'}}
                    className="mb-0 badge" 
                    onClick={e=>this.deleteTransactionByInvoiceId(cell.original.invoiceId)}
                    >
                      <i className="mdi mdi-delete mdi-18px"></i>
                  </a>
                  }
                  {cell.original && cell.original.invoiceInfo && cell.original.invoiceInfo.notes &&
                    (<a title='Detail' id={'detail'}
                    style={{paddingRight: '0px',
                    paddingTop: '0px',
                    paddingBottom: '0px',cursor:'pointer'}}
                    className="mb-0 badge" 
                    onClick={e=>this.noteDetail(cell.original)}
                    >
                      <i className="mdi mdi-information-outline mdi-18px"></i>
                  </a>)
                  }
            </Row>
          )
        }
      },
    ]
    const otherInvoiceColumn=[
      {
        Header: 'Name',
        accessor: 'Name',
        width:225,
        Cell: (cell)=>{
          if(cell.original.invoiceInfo.otherFeeList){
            return cell.original.invoiceInfo.otherFeeList.map((data, index)=>{
              return<div key={data.name+index}className="py-1">
                {data.name.toUpperCase()}{`(${data.amount}/-)`}
                {(index!== cell.original.invoiceInfo.otherFeeList.length-1)?<hr className="mt-1 mb-0"></hr>:''}
              </div>
            })
          }else{
            return <div className="py-1"></div>
          }
        }
      }, 
      {
        Header: 'Invoice No',
        accessor: 'invoiceId',
        width:75,
      },
      {
        Header: 'Rec.No.',
        accessor: 'invoiceInfo.receiptNumber',
        width:58,
      },
      {
        Header: "Date",
        accessor: "date",
        width: 72,
        Cell: (cell)=>{
         return cell.original.invoiceInfo.submittedDate?  new Date(cell.original.invoiceInfo.submittedDate).toLocaleDateString("en-GB"): ''
        }
      },
      {
        Header: "Total Amt",
        accessor: "Total Amount",
        width: 75,
        Cell: (cell)=>{
         return <div className="text-center">{cell.original.invoiceInfo.totalAmount}/-</div>
        }
      },
      {
        Header: "Concession",
        accessor: "concession",
        width: 78,
        Cell: (cell)=>{
         return cell.original.invoiceInfo.concession? <div className="text-center">{cell.original.invoiceInfo.concession}/-</div>:''
         //return''
        }
      },
      {
        Header: "Dues",
        accessor: "invoiceInfo.dueAmount",
        width: 110,
        Cell: (cell)=>{
          return cell.original.invoiceInfo.dueAmount?<div className="text-center">{cell.original.invoiceInfo.dueAmount}{cell.original.invoiceInfo.dueFor?(<span><br/>{cell.original.invoiceInfo.dueFor.toUpperCase()}</span>):''}</div>:''
          //return <div><br/></div>
         }
      },
      {
        Header: "Paid Amount",
        accessor: "Paid Amount",
        width: 90,
        Cell: (cell)=>{
         //return <span style={{fontWeight:'bold'}}>{cell.original.amount}/-</span>
         if(cell.original.invoiceInfo.otherFeeList){
          return <span style={{fontWeight:'bold'}}>{cell.original.invoiceInfo.paidAmount}/-</span>
          // return cell.original.invoiceInfo.otherFeeList.map((data, index)=>{
          //   return<div className="py-1">
          //     <span style={{fontWeight:'bold'}}>{data.amount}/-</span>
          //     {(index!== cell.original.invoiceInfo.otherFeeList.length-1)?<hr className="mt-1 mb-0"></hr>:''}
          //   </div>
          // })
        }else{
          return <div className="py-1"></div>
        }
        }
      },
      {
        Header: "Reciever Name",
        accessor: "Reciever Name",
        width: 140,
        Cell: (cell)=>{
          const paymentRecieverId = cell.original.invoiceInfo.paymentRecieverId
          const paymentRecieverData = paymentRecieverId? paymentRecieverUserList.find(it=> it._id.toString()===paymentRecieverId): {}
          if (paymentRecieverId){
            return paymentRecieverData && paymentRecieverData.userInfo? paymentRecieverData.userInfo.fullName: 'Not Found' 
          }else{
            return ''
          }
        }
      },
      {
        Header: "Action",
        //accessor: "userInfo.class",
        //width: 50,
        Cell: (cell)=>{
          //console.log("cell.original[monthName].payEnable",monthName, !!cell.original.userPayDetail && !!cell.original.userPayDetail[monthName])
          return(
            <Row style={{marginLeft:"0.1rem", marginTop:"0px"}}>
                  {cell.original.invoiceId &&
                    (<a title='Detail' id={'detail'}
                    style={{paddingRight: '0px',
                    paddingTop: '0px',
                    paddingBottom: '0px',cursor:'pointer'}}
                    className="mb-0 badge" 
                    onClick={e=>this.donloadPayDetail(cell.original.invoiceId)}
                    >
                      <i className="mdi mdi-download mdi-18px"></i>
                  </a>)
                  }
                  {USER && USER.userInfo && USER.userInfo.roleName && USER.userInfo.roleName==='TOPADMIN' &&
                  <a title='Delete' id={'deleteTx'}
                    style={{paddingRight: '0px',
                    paddingTop: '0px',
                    paddingBottom: '0px',cursor:'pointer'}}
                    className="mb-0 badge" 
                    onClick={e=>this.deleteTransactionByInvoiceId(cell.original.invoiceId)}
                    >
                      <i className="mdi mdi-delete mdi-18px"></i>
                  </a>
                  }
                  {cell.original && cell.original.invoiceInfo && cell.original.invoiceInfo.notes &&
                    (<a title='Detail' id={'detail'}
                    style={{paddingRight: '0px',
                    paddingTop: '0px',
                    paddingBottom: '0px',cursor:'pointer'}}
                    className="mb-0 badge" 
                    onClick={e=>this.noteDetail(cell.original)}
                    >
                      <i className="mdi mdi-information-outline mdi-18px"></i>
                  </a>)
                  }
            </Row>
          )
        }
      },
    ]
   
    return (
      <div>
      <BlockUi tag="div"  blocking={this.state.loading} className="block-overlay-dark" loader={<Spinner/>}>
      <div className="row">
        <div className="col-12 grid-margin">
          <div className="card">
            <div className="card-body">
              <p className="card-title">All Students Payment Report <span style={{color: 'red'}}> ({this.state.selectedSession})</span></p>
              <p className="card-title2">Total Student Count: {this.state.allPayDetail.length}</p>
              <Row>
                  <Col>
                  <AvForm>
                    <AvInput tag={CustomInput} id="directPrint1" type="checkbox" name="directPrint"
                      label={<span style={{color: "green"}}>Print On</span>}
                      checked={this.state.isPrint}
                      onChange={()=>this.setState({isPrint: !this.state.isPrint})}
                    />
                    </AvForm>
                  </Col>
                  <Col>
                  <AvForm>
                  <button className="no-decoration-button" onClick={()=>this.calculateAllDues()}>
                                Total Dues : {this.state.loadingCal ? (
                                <>Loading...</> 
                              ) : (
                                this.state.totalDuesOfClass  !== null && <>{formatToIndianCurrency(this.state.totalDuesOfClass)}</>
                              )}
                              </button>
                    </AvForm>
                  </Col>
                </Row>
              <Row>
              <Col md={3} style={{marginRight:'-2rem'}} >
              <Form.Group>
                  {/* <label>Small input</label> */}
                  <Form.Control type="text" className="form-control" 
                  placeholder="Search by name, id, phone number, etc." aria-label="Username"
                  value={this.state.inputSearch? this.state.inputSearch:''}
                  onChange={this.handleSearch}
                   />
                </Form.Group>
              </Col>
              <Col md={2} style={{marginRight:'-2rem'}} >
              <Form.Group>
                  {/* <label htmlFor="exampleFormControlSelect3">Small select</label> */}
                  <select className="form-control form-control-sm" id="exampleFormControlSelect3"
                    onChange={this.handleClass}
                    value={this.state.selectedClass? this.state.selectedClass:''}
                    >
                    <option value=''>Select Class</option>
                    {classList.map((data, index)=> 
                    <option key={index} value={data}> {data}</option>
                    )}
                  </select>
                </Form.Group>
              </Col>
              <Col md={2} style={{marginRight:'-2rem'}}>
              <Form.Group>
                  {/* <label htmlFor="exampleFormControlSelect3">Small select</label> */}
                  <select className="form-control form-control-sm" id="exampleFormControlSelect3"
                    onChange={this.handleSession}
                    value={this.state.selectedSession? this.state.selectedSession:''}
                    >
                    <option value=''>Seletc Session</option>
                    {sessionList.map((data, index)=> 
                    <option key={index} value={data}> {data}</option>
                    )}
                  </select>
                </Form.Group>
              </Col>
              <Col md={2} style={{marginRight:'-2rem'}}>
              {/* <Form.Group> */}
                  <Select 
                    value={selectedUserId?allStudentUserIdList.filter((data)=>data.value===selectedUserId):''}
                    //isClearable
                    onChange={(e) => this.handleUserIdChange(e)}
                    options={allStudentUserIdList}
                    placeholder="User Id"
                    //className="form-control"
                    //isSearchable
                    unstyled
                    //styles={{"display": "block","marginLeft": "9.5px",'float':'right',menu: provided => ({ ...provided, zIndex: 999999 })}}
                    maxMenuHeight={200}
                    styles={{
                      control: (baseStyles, state) => ({
                          ...baseStyles,
                          minHeight: '34px',
                          fontSize: '13px',
                      }),
                      dropdownIndicator: (base) => ({
                          ...base,
                          paddingTop: 0,
                          paddingBottom: 0,
                      }),
                      menuList: (base) => ({
                          ...base,
                          fontSize: '12px',
                      }),
                      menu: provided => ({ ...provided, zIndex: 9999 })
                    }}
                   />
                {/* </Form.Group> */}
              </Col>
              {/* <Col md={3} style={{marginRight:'-2rem'}}>
                  <Select 
                    value={selectedPhoneNo?allStudentPhoneList.filter((data)=>data.value===selectedPhoneNo):''}
                    //isClearable
                    onChange={(e) => this.handlePhoneSearch(e)}
                    options={allStudentPhoneList}
                    placeholder="Phone number"
                    //className="form-control"
                    //isSearchable
                    unstyled
                    //styles={{"display": "block","marginLeft": "9.5px",'float':'right',menu: provided => ({ ...provided, zIndex: 999999 })}}
                    maxMenuHeight={200}
                    styles={{
                      control: (baseStyles, state) => ({
                          ...baseStyles,
                          minHeight: '34px',
                          fontSize: '13px',
                      }),
                      dropdownIndicator: (base) => ({
                          ...base,
                          paddingTop: 0,
                          paddingBottom: 0,
                      }),
                      menuList: (base) => ({
                          ...base,
                          fontSize: '12px',
                      }),
                    }}
                   />
               
              </Col> */}
              <Col md={2} style={{marginRight:'-2rem'}}>
              <Form.Group>
                <Button className="btn btn-warning btn-sm" style={{fontWeight:"90", color:"black"}}
                onClick={this.clearFilter}
                >Clear Filter</Button>
                 </Form.Group>
              </Col>
               {/* {USER && USER.userInfo && USER.userInfo.roleName=='TOPADMIN' &&
              <Col md={2}>
              <Form.Group>
                <Button className="btn btn-warning btn-sm" style={{fontWeight:"90", color:"black"}}
                //disabled={!this.state.selectedClass}
                onClick={this.generateAdmitCard}
                >Download Admit Card</Button>
                 </Form.Group>
              </Col> } */}
              
              <Col md={3} style={{marginRight:'-2rem'}}>
              <Form.Group>
                <Button className="btn btn-warning btn-sm" style={{fontWeight:"90", color:"black"}}
                disabled={this.state.allPayDetail.length===0}
                onClick={this.exportDuesCSV}
                >Download Dues Info</Button>
                 </Form.Group>
              </Col> 
              </Row>
              <ReactTable
                data={this.state.allPayDetail}
              //className='-striped -highlight'
                className='-highlight'
                columns={columns}
                //defaultSorted={[{ id: "created", desc: true }]}
                pageSize={10}
                showPageSizeOptions={false}
                showPageJump={false}
                getTrProps={this.getTrProps}
                // getTheadThProps={(state, rowInfo, column, instance) => {
                //   return {
                //     onClick: (e, handleOriginal) => {

                //       if(column && column.Header && column.Header.toLowerCase()==='class'){
                //           this.sortData('Class')
                //       }
                //       // if(column.Header.toLocaleLowerCase()==='email'){
                //       //     this.sortData('email')
                //       // }
                //       // if(column.Header.toLocaleLowerCase()==='logged in user'){
                //       //     this.sortData('user')
                //       // }
                //       // if(column.Header.toLocaleLowerCase()==='status'){
                //       //     this.sortData('status')
                //       // }
                //     }
                //   }
                // }}
              />
            </div>
          </div>
        </div> 
        </div>  
      </BlockUi>
 
        <Modal
            show={this.state.paymentModal}
            onHide={this.handleClose}
            size={'lg'}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            >
          <Modal.Header closeButton>
          <Modal.Title style={{marginBottom: '-2rem'}}>Monthly Payment (<span style={{color:'red'}}>{this.state.selectedSession}</span>)
          <br/>
          <small>{selectedUser.userInfo?`${selectedUser.userInfo.fullName} (${this.state.selectedClass?this.state.selectedClass:selectedUser.userInfo.class})`:''}({selectedUser.userInfo && selectedUser.userInfo.fatherName})({selectedUser.userInfo && selectedUser.userInfo.motherName})</small>
          
          </Modal.Title>
          </Modal.Header>
          <Modal.Body >
          <BlockUi tag="div" blocking={this.state.loading4}  className="block-overlay-dark"  loader={<Spinner/>}>
          <div className="card"> 
              <div className="card-body">
              <AvForm onValidSubmit={(error, values)=>this.handlePaymentSubmit(error, values,'MONTHLY')}>
                <Row>
                <Col md={3} >
                  <AvField type="select" name="paymentRecieverId" label="Select Payment Reciever"
                        validate={{
                        required: {
                            value: true,
                            errorMessage: 'This field is required.'
                        }
                    }} 
                    >
                    <option key='choose_pay_01' value=''>Choose Payment Reciever</option>
                    {paymentRecieverUserList.length>0 && paymentRecieverUserList.map((data, index)=> {return (<option key={'pay'+index}  value={data._id}style={{color:"black"}}>{data.userInfo.fullName}</option>)} )}
                  </AvField>
                  </Col>
                  <Col md={3}>
                
                  <label style={{color:submittedDateError?"red":null, fontSize:"14px", marginBottom:"0.2rem"}}
                    >Submit Date </label>
                  <Form.Group>
                        <DatePicker 
                        className={submittedDateError?"form-control w-100 dob-border-color":"form-control w-100"}
                        dateFormat="dd/MM/yyyy"
                          selected={this.state.submittedDate}
                          maxDate={new Date()}
                          onChange={this.handleChange}
                        />
                        {submittedDateError?<span className="error-msg-style" style={{marginLeft: "100px"}}>Invalid DOB </span>:null}
                  </Form.Group>
                  </Col>
                  <Col md={3} >
                  <AvField type="text" name="phoneNumber1" label="Phone Number1 "
                      value={selectedUser.userInfo && selectedUser.userInfo.phoneNumber1?selectedUser.userInfo.phoneNumber1:'' }
                      onChange={this.onChangePhoneNumber('phoneNumber1')}
                      validate={{
                      required: {
                          value: true,
                          errorMessage: 'This field is required.'
                        },
                        maxLength: {
                          value: 10,
                          errorMessage: 'Invalid phone number 1'
                        },
                        minLength: {
                          value: 10,
                          errorMessage: 'Invalid phone number 1'
                        },
                        pattern: {
                          value:/^[0-9]+$/,
                          errorMessage: `Invalid phone number 1.`
                        }
                      }} 
                    >
                  </AvField>
                  </Col>
                  <Col md={3}>
                  <AvField type="text" name="phoneNumber2" label="Phone Number 2"
                      value={selectedUser.userInfo && selectedUser.userInfo.phoneNumber2?selectedUser.userInfo.phoneNumber2:'' }
                      onChange={this.onChangePhoneNumber('phoneNumber2')}
                      validate={{
                      required: {
                          value: false,
                          errorMessage: 'This field is required.'
                        },
                        maxLength: {
                          value: 10,
                          errorMessage: 'Invalid phone number 2'
                        },
                        minLength: {
                          value: 10,
                          errorMessage: 'Invalid phone number 2'
                        },
                        pattern: {
                          value:/^[0-9]+$/,
                          errorMessage: `Invalid phone number 2`
                        }
                      }} 
                    >
                  </AvField>
                  </Col>
                  </Row>
                  {this.state.feeList.map((data, indexCount)=><>
                    <Row>
                          <Col md={3}>
                            <AvField type="select" name={`feeArray[${indexCount}].month`} label="Select Month"
                            disabled={data.month?true:false}
                            onChange={(e)=>this.changeMonth(e, indexCount, 'month')}
                            value={data.month}
                                  validate={{
                                  required: {
                                      value: true,
                                      errorMessage: 'This field is required.'
                                  }
                              }} >
                              <option key='choose_month' value=''>Choose Month</option>
                              {(data.month?monthList:this.state.updateMonthList).map((data, index)=> {return (<option key={'month_'+index} value={data} style={{color:"black"}}>{_.startCase(data)}</option>)} )}
                            </AvField>
                          </Col>
                          { this.state.userPayDetail && !(!!this.state.userPayDetail.feeFree) && 
                          <Col md={2}>
                            <AvField name={`feeArray[${indexCount}].monthlyFee`} label="Monthly Fee" placeholder="0.00"
                              value={data.monthlyFee}
                              disabled={true}
                              />
                          </Col>
                          }
                          {this.state.userPayDetail &&  this.state.userPayDetail.busService && 
                            <>
                              <Col md={3}>
                                <AvField type="select" name={`feeArray[${indexCount}].busRouteId`} label="Select Route"
                                  disabled={true}
                                  value={selectedUser.userInfo && selectedUser.userInfo.busRouteId?selectedUser.userInfo.busRouteId:''}
                                  validate={{
                                      required: {
                                          value: true,
                                          errorMessage: 'This field is required.'
                                      }
                                  }} >
                                  <option  key='choosebus' value=''>Choose Bus Route</option>
                                  {busRouteFareList.map((data, index)=> {return (<option key={'bus_'+index} value={data._id} style={{color:"black"}}>{data.route}</option>)} )}
                                </AvField>
                              </Col>
                              <Col md={2}>
                                <AvField name={`feeArray[${indexCount}].busFee`} label="Bus Fee" placeholder="0.00"
                                value={data.busFee}
                                disabled={true}
                                />
                              </Col>
                            </>
                          }
                          <Col md={2} className='text-center'>
                           <label style={{marginBottom:'2.5rem'}}>{" "}</label> 
                          <button type="button" className="btn btn-sm btn-outline-light btn-rounded btn-icon" 
                            onClick={()=>this.removeRow(indexCount, 'feeList')}>
                            <i className="mdi mdi-minus-circle text-danger"></i>
                          </button>
                        </Col>
                     </Row>     
                  </>)}
                  {this.state.updateMonthList && this.state.updateMonthList.length>0 && 
                      <Col md={2} >
                         <button type="button" className="btn btn-inverse-primary btn-rounded btn-icon" onClick={()=>this.addRow('feeList')}>
                           <i className="mdi mdi-plus-circle"></i>
                         </button>
                      </Col>
                  }
             
                  <Row>
                  <Col md={3}>
                    <AvField name="fineAmount" label="Fine" placeholder="0.00"
                      onKeyUp={(e)=>this.onAmountChange(e,'fine')}
                    //value={!!selectedUser && selectedUser.userInfo && selectedUser.userInfo.fatherName}     
                      validate={{
                      async: function (value, ctx, input, cb) {
                        const pattern = /^[0-9]+$/
                          if(value && !pattern.test(value)){
                            cb('Invalid Number')
                          }else{
                            cb(true)
                          }
                        }
                      }}
                    />
                  </Col>
                  <Col md={3}>
                    <AvField name="concession" label="Concession" placeholder="0.00"
                     
                    //value={!!selectedUser && selectedUser.userInfo && selectedUser.userInfo.fatherName}     
                        validate={{
                        async: function (value, ctx, input, cb) {
                          const pattern = /^[0-9]+$/
                            if(value && !pattern.test(value)){
                              cb('Invalid Number')
                            }else{
                              if (value && Number(value)> Number(totalGrandAmount)) {
                                cb('Invalid concession')
                              }else{
                                cb(true)
                              }
                            }
                          }
                        }}
                        onKeyUp={(e)=>this.onAmountChange(e,'concession')}
                    />
                  </Col>
                  <Col md={3}>
                    <AvField name="receiptNumber" label="Receipt Number" placeholder="000"
                    //value={!!selectedUser && selectedUser.userInfo && selectedUser.userInfo.fatherName}     
                        // validate={{
                        // required: {
                        //     value: true,
                        //     errorMessage: 'This field is required.'
                        // },
                        // pattern: {
                        //   value:  /^[a-zA-Z][a-zA-Z\s]*$/,
                        //   errorMessage: `Invalid father name.`
                        // }
                        // }}
                    />
                  </Col>
                  </Row>
                    <Row>
                      <Col md={3}>
                        <AvField name="dueAmount" label="Due Amount" placeholder="0.00"
                        disabled={true}
                        value={dueAmount}     
                        />
                      </Col>
                      <Col md={3}>
                        <AvField name="preDueAmount" label="Over-Due Amount" placeholder="0.00"
                        disabled={true}
                        value={preDueAmount}     
                        />
                      </Col>
                      <Col md={3}>
                        <AvField name="excessAmount" label="Excess Amount" placeholder="0.00"
                        disabled={true}
                        value={excessAmount}     
                        />
                      </Col>
                      <Col md={3}>
                        <AvField name="preExcessAmount" label="Over-Excess Amount" placeholder="0.00"
                        disabled={true}
                        value={preExcessAmount}     
                        />
                      </Col>
                  <Col md={6}>
                  <AvField name="paidAmount" label="Final Paid Amount" placeholder="0.00"
                    disabled={true}
                    value={paidAmount}     
                   />
                   </Col>
                   <Col md={6}>
                  <AvField name="totalAmount" label="Final Total Amount" placeholder="0.00"
                    disabled={true}
                    value={totalAmount}     
                   />
                   </Col>
                  </Row>
                  <Row>
                    <Col md={7}>
                    {payList.map((data, indexNum)=>{
                      return (
                        <>
                        <Row>
                        <Col md={5}>
                          <AvField type="select" name={`payment[${indexNum}].payModeId`} label="Select Payment Mode"
                          onChange ={this.updatePaymentField(indexNum, 'payModeId')}
                           value={data.payModeId} 
                                validate={{
                                required: {
                                    value: true,
                                    errorMessage: 'This field is required.'
                                }
                            }} >
                            <option key='choose01' value=''>Choose Payment Mode</option>
                            <option key='cash01' style={{color:"black"}} value='Cash'>Cash</option>
                            {payOptionList.map((data, index)=> 
                                {return (
                                <option key={`pay_${index}`} value={data._id} style={{color:"black"}}>
                                    {data.payMethod==='BANK'?`${data.payOptionInfo.bankName} (${data.payOptionInfo.accountNumber})` :`${data.payOptionInfo.upiType} (${data.payOptionInfo.upiId})`}
                              </option>
                              )}
                            )}
                          </AvField>
                        </Col>
                        <Col md={5}>
                          <AvField name={`payment[${indexNum}].amount`} label="Amount" placeholder="0.00"
                          type="number"
                          onKeyUp={e=> this.calculatePaymentField(e, indexNum)}
                          onChange ={this.updatePaymentField(indexNum, 'amount')}
                            value={data.amount}     
                              validate={{
                              required: {
                                  value: true,
                                  errorMessage: 'This field is required.'
                              },
                              // pattern: {
                              //   value:  /^[a-zA-Z][a-zA-Z\s]*$/,
                              //   errorMessage: `Invalid father name.`
                              // }
                                async: function (value, ctx, input, cb) {
                                  const pattern = /^[0-9]+$/
                                    if(value && !pattern.test(value)){
                                      cb('Invalid Number')
                                    }else{
                                      cb(true)
                                    }
                                  }
                              }}
                          />
                          </Col>
                            <Col md={2} className='text-center'>
                              <label style={{marginBottom:'2.5rem'}}>{" "}</label> 
                                <button type="button" className="btn btn-sm btn-outline-light btn-rounded btn-icon" 
                                  onClick={()=>this.removeRow(indexNum, 'payList')}>
                                <i className="mdi mdi-minus-circle text-danger"></i>
                              </button>
                            </Col>
                        </Row>
                        </>
                      )
                    })}
                    </Col>
                    <Col md={5}>
                      <AvField name="notes" label="Add Note (If required)" placeholder=" Write note here"
                          />
                    </Col>
                     </Row>
                    <Row>
                      <Col md={1} >
                        <button type="button" className="btn btn-inverse-primary btn-rounded btn-icon" onClick={()=>this.addRow('payList')}>
                          <i className="mdi mdi-plus-circle"></i>
                        </button>
                      </Col>
                      <Col>
                      <div className="form-check">
                        <label className="form-check-label text-muted" style={{color: "green"}}>
                          <input type="checkbox" id="directPrint3" className="form-check-input"
                          checked={this.state.isPrint}
                          onChange={()=>this.setState({isPrint: !this.state.isPrint})}
                          />
                          <i className="input-helper"></i>
                          Print On
                        </label>
                      </div>
                        {/* <AvInput tag={CustomInput} id="directPrint2" type="checkbox" name="directPrint"
                          label={<span style={{color: "green"}}>Print On</span>}
                          checked={this.state.isPrint}
                          onChange={()=>this.setState({isPrint: !this.state.isPrint})}
                        /> */}
                    </Col>
                    </Row>
                 
                  <Row>
                      <Col className='text-center'>
                      <Button variant="secondary" onClick={this.handleClose} >Cancel</Button>
                      </Col>
                      <Col className='text-center'>
                      <Button type="submit">Submit</Button>
                      </Col>
                  </Row>
                </AvForm>
              </div>
            </div>
            </BlockUi>
          </Modal.Body>
        </Modal>

        <Modal
            show={this.state.viewPaymentDetail}
            onHide={this.handleClose}
            size={'lg'}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            >
          <Modal.Header closeButton>
          <Modal.Title style={{marginBottom: '-2rem'}}>
            <small>{selectedUser.userInfo?`${selectedUser.userInfo.fullName} (${selectedUser.userInfo && selectedUser.userInfo.userId}) (${this.state.selectedClass?this.state.selectedClass:selectedUser.userInfo.class})`:''}({selectedUser.userInfo && selectedUser.userInfo.fatherName})({selectedUser.userInfo && selectedUser.userInfo.motherName})
            Total Paid Amount:{this.state.userAllInvoice.reduce((sum, curr)=> sum+ Number(curr.amount),0)}/-</small>
          </Modal.Title>
          </Modal.Header>
          <Modal.Body >
          <BlockUi tag="div" blocking={this.state.deleteLoading}  className="block-overlay-dark"  loader={<Spinner/>}>
          {/* <div className="card"> 
              <div className="card-body">
                <ReactTable
                  data={this.state.userInvoice}
                  className='-striped -highlight'
                  //className='-highlight'
                  columns={monthlyFeeColumn}
                  pageSize={12}
                  showPageSizeOptions={false}
                  showPageJump={false}
                  showPagination={false}
                />
               
              </div>
               <span>Total Paid Amount: {this.state.totalPaidAmount}</span> 
          </div> */}
            <div className="card"> 
              <div className="card-body" style={{padding: "0.5rem 0.5rem"}} >
                <ReactTable
                  data={this.state.userMonthlyInvoice? this.state.userMonthlyInvoice:[]}
                  className='-striped -highlight'
                  //className='-highlight'
                  NoDataComponent={() => null}
                  columns={monthlyInvoiceColumn}
                  pageSize={this.state.pageSizeOfViewPayment?this.state.pageSizeOfViewPayment:12}
                  showPageSizeOptions={false}
                  showPageJump={false}
                  showPagination={false}
                />
                <ReactTable
                  data={this.state.userOtherInvoice? this.state.userOtherInvoice:[]}
                  className='-striped -highlight'
                  //className='-highlight'
                  NoDataComponent={() => null} 
                  columns={otherInvoiceColumn}
                  pageSize={this.state.userOtherInvoice && this.state.userOtherInvoice.length>0 ? this.state.userOtherInvoice.length:1}
                  showPageSizeOptions={false}
                  showPageJump={false}
                  showPagination={false}
                />
              </div>
               <span></span> 
            </div>
            </BlockUi>
          </Modal.Body>
        </Modal>
        <Modal
            show={this.state.contactModalShow}
            onHide={this.handleClose}
            //size={'lg'}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            >
          <Modal.Header closeButton>
          <Modal.Title style={{marginBottom: '-2rem'}}>
            <small>{selectedUser.userInfo?`${selectedUser.userInfo.fullName} (${this.state.selectedClass? this.state.selectedClass:selectedUser.userInfo.class})`:''}({selectedUser.userInfo && selectedUser.userInfo.fatherName})({selectedUser.userInfo && selectedUser.userInfo.motherName})</small>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body >
          <BlockUi tag="div" blocking={this.state.loading4}  className="block-overlay-dark"  loader={<Spinner/>}>
          <div className="card"> 
              <div className="card-body">
              {selectedUser && selectedUser.userInfo && selectedUser.userInfo.phoneNumber1 && 
                <div className="card-header" ref={this.anchorRef}>
                  <a id="myAnchor" href={`tel:${selectedUser.userInfo.phoneNumber1}`} style={{fontSize:'1.5rem'}} > <i className="mdi mdi mdi-phone mdi-24px text-success"></i>{selectedUser.userInfo.phoneNumber1}</a> 
                     {/* <button onClick={()=>this.copyToClipBoard(selectedUser.userInfo.phoneNumber1)}>Copy</button> */}
                  <a title='Copy' id={'copy1'}
                    style={{paddingRight: '0px',
                    paddingTop: '0px',
                    paddingBottom: '0px',cursor:'pointer'}}
                    className="mb-0 badge" 
                    onClick={()=>this.copyToClipBoard(selectedUser.userInfo.phoneNumber1)}
                    >
                      <i className="mdi mdi-content-paste mdi-24px"></i>
                  </a>
                </div>
              }
              {selectedUser && selectedUser.userInfo && selectedUser.userInfo.phoneNumber2 && 
                <div className="card-header">
                  <a href={`tel:${selectedUser.userInfo.phoneNumber2}`} style={{fontSize:'1.5rem'}}> <i className="mdi mdi mdi-phone mdi-24px text-dark"></i>{selectedUser.userInfo.phoneNumber2}</a>
                  {/* <button onClick={()=>this.copyToClipBoard(selectedUser.userInfo.phoneNumber2)}>Copy</button> */}
                  <a title='Copy' id={'copy2'}
                    style={{paddingRight: '0px',
                    paddingTop: '0px',
                    paddingBottom: '0px',cursor:'pointer'}}
                    className="mb-0 badge" 
                    onClick={()=>this.copyToClipBoard(selectedUser.userInfo.phoneNumber2)}
                    >
                      <i className="mdi mdi-content-paste mdi-24px"></i>
                  </a>
                </div>
              }
              <div className="card-header" >Dues Amount: {this.state.duesAmount}/-</div>
              </div>
            </div>
            </BlockUi>
          </Modal.Body>
        </Modal>
        <Modal
            show={this.state.exmaPayModalShow}
            onHide={this.closeExamPayModal}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            >
          <Modal.Header closeButton>
          <Modal.Title style={{marginBottom: '-2rem'}}> {this.state.examName} FEE (<span style={{color:'red'}}>{this.state.selectedSession}</span>)
              <br/>
              <small>{selectedUser.userInfo?`${selectedUser.userInfo.fullName} (${this.state.selectedClass?this.state.selectedClass:selectedUser.userInfo.class})`:''}({selectedUser.userInfo && selectedUser.userInfo.fatherName})({selectedUser.userInfo && selectedUser.userInfo.motherName})</small> 
          </Modal.Title>
          
          </Modal.Header>
          <Modal.Body >
            <BlockUi tag="div" blocking={this.state.loading4}  className="block-overlay-dark"  loader={<Spinner/>}>
              <div className="card">
                <div className="card-body">
                <AvForm 
                onValidSubmit={(error, values)=>this.handlePaymentSubmit(error, values,'EXAM_FEE')}
                ref={c => (this.form = c)} 
                >
                  <Row>
                    <Col>
                      <AvField type="select" name="paymentRecieverId" label="Select Payment Reciever"
                            validate={{
                            required: {
                                value: true,
                                errorMessage: 'This field is required.'
                            }
                        }} 
                        >
                        <option value=''>Choose Payment Reciever</option>
                        {paymentRecieverUserList.length>0 && paymentRecieverUserList.map((data, index)=> {return (<option key={index}  value={data._id}style={{color:"black"}}>{data.userInfo.fullName}</option>)} )}
                      </AvField>
                    </Col>
                    <Col>
                        <label   style={{color:submittedDateError?"red":null, fontSize:"14px", marginBottom:"0.2rem"}}
                          >Submit Date </label>
                        <Form.Group>
                              <DatePicker 
                              className={submittedDateError?"form-control w-100 dob-border-color":"form-control w-100"}
                              dateFormat="dd/MM/yyyy"
                                selected={this.state.submittedDate}
                                maxDate={new Date()}
                                onChange={this.handleChange}
                              />
                              {submittedDateError?<span className="error-msg-style" style={{marginLeft: "100px"}}>Invalid DOB </span>:null}
                        </Form.Group>
                      </Col>
                  </Row>
                
                      {payList.map((data, indexNum)=>{
                        return (
                          <>
                          <Row>
                          <Col>
                            <AvField type="select" name={`payment[${indexNum}].payModeId`} label="Select Payment Mode"
                            onChange ={this.updatePaymentField(indexNum, 'payModeId')}
                            value={data.payModeId} 
                                  validate={{
                                  required: {
                                      value: true,
                                      errorMessage: 'This field is required.'
                                  }
                              }} >
                              <option value=''>Choose Payment Mode</option>
                              <option style={{color:"black"}} value='Cash'>Cash</option>
                              {payOptionList.map((data, index)=> 
                                  {return (
                                  <option key={index+'pay'} value={data._id} style={{color:"black"}}>
                                      {data.payMethod==='BANK'?`${data.payOptionInfo.bankName} (${data.payOptionInfo.accountNumber})` :`${data.payOptionInfo.upiType} (${data.payOptionInfo.upiId})`}
                                </option>
                                )}
                              )}
                            </AvField>
                          </Col>
                          <Col >
                            <AvField name={`payment[${indexNum}].amount`} label="Amount" placeholder="0.00"
                            //onKeyUp={e=> this.calculatePaymentField(e, indexNum)}
                              onChange ={this.updatePaymentField(indexNum, 'amount')}
                              disabled={ROLENAME==='ACCOUNTANT'?true:false}  
                              value={data.amount}     
                                validate={{
                                required: {
                                    value: true,
                                    errorMessage: 'This field is required.'
                                },
                                // pattern: {
                                //   value:  /^[a-zA-Z][a-zA-Z\s]*$/,
                                //   errorMessage: `Invalid father name.`
                                // }
                                  async: function (value, ctx, input, cb) {
                                    const pattern = /^[0-9]+$/
                                      if(value && !pattern.test(value)){
                                        cb('Invalid Number')
                                      }else{
                                        cb(true)
                                      }
                                    }
                                }}
                            />
                            </Col>
                              {/* <Col md={2} className='text-center'>
                                <label style={{marginBottom:'2.5rem'}}>{" "}</label> 
                                  <button type="button" className="btn btn-sm btn-outline-light btn-rounded btn-icon" 
                                    onClick={()=>this.removeRow(indexNum, 'payList')}>
                                  <i className="mdi mdi-minus-circle text-danger"></i>
                                </button>
                              </Col> */}
                          </Row>
                          </>
                        )
                      })}
                    <Row>
                      <Col>
                        <AvField name="receiptNumber" 
                          label="Receipt Number"
                          placeholder="000"
                          validate={{
                            required: {
                                value: true,
                                errorMessage: 'This field is required.'
                            },
                            }}
                        />
                      </Col>
                      <Col>
                        <AvField name="notes" label="Add Note (If required)" placeholder=" Write note here"/>
                      </Col>
                    </Row>  
                    <Row>
                        <Col className='text-center'>
                        <Button type="submit">Submit</Button>
                        </Col>
                        <Col className='text-center'>
                        <Button variant="secondary" onClick={this.closeExamPayModal} >Cancel</Button>
                        </Col>
                    </Row>
                  </AvForm>
                </div>
              </div>
            </BlockUi>
          </Modal.Body>
        </Modal>
        <Modal
            show={this.state.otherPayModal}
            onHide={this.closeOtherPayModal}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            >
          <Modal.Header closeButton>
          <Modal.Title style={{marginBottom: '-2rem'}}> Other Payment (<span style={{color:'red'}}>{this.state.selectedSession}</span>)
              <br/>
              <small>{selectedUser.userInfo?`${selectedUser.userInfo.fullName} (${this.state.selectedClass? this.state.selectedClass:selectedUser.userInfo.class})`:''}({selectedUser.userInfo && selectedUser.userInfo.fatherName})({selectedUser.userInfo && selectedUser.userInfo.motherName})</small> 
          </Modal.Title>
          </Modal.Header>
          <Modal.Body >
            <BlockUi tag="div" blocking={this.state.loading4}  className="block-overlay-dark"  loader={<Spinner/>}>
              <div className="card">
                <div className="card-body">
                <AvForm 
                onValidSubmit={(error, values)=>this.handlePaymentSubmit(error, values,'OTHER_PAYMENT')}
                ref={c => (this.form = c)} 
                >
                  <Row>
                    <Col style={{marginRight:'-1rem'}}>
                      <AvField type="select" name="paymentRecieverId" label="Select Payment Reciever"
                            validate={{
                            required: {
                                value: true,
                                errorMessage: 'This field is required.'
                            }
                        }} 
                        >
                        <option value=''>Choose Payment Reciever</option>
                        {paymentRecieverUserList.length>0 && paymentRecieverUserList.map((data, index)=> {return (<option key={index}  value={data._id}style={{color:"black"}}>{data.userInfo.fullName}</option>)} )}
                      </AvField>
                    </Col>
                    <Col >
                        <label   style={{color:submittedDateError?"red":null, fontSize:"14px", marginBottom:"0.2rem"}}
                          >Submit Date </label>
                        <Form.Group>
                              <DatePicker 
                              className={submittedDateError?"form-control w-100 dob-border-color":"form-control w-100"}
                              dateFormat="dd/MM/yyyy"
                                selected={this.state.submittedDate}
                                maxDate={new Date()}
                                onChange={this.handleChange}
                              />
                              {submittedDateError?<span className="error-msg-style" style={{marginLeft: "100px"}}>Invalid DOB </span>:null}
                        </Form.Group>
                      </Col>
                  </Row>
                  {otherPaymentList.map((data, indexNum)=>{
                        return (
                          <>
                          <Row>
                          <Col style={{marginRight:'-1rem'}}>
                            <AvField type="select" name={`otherPayment[${indexNum}].payFor`} label="Select Payment For"
                            onChange ={this.updateOtherPaymentField(indexNum, 'payFor')}
                            value={data.payFor} 
                                  validate={{
                                  required: {
                                      value: true,
                                      errorMessage: 'This field is required.'
                                  }
                              }} >
                            
                              <option value=''>Choose one</option>
                              {[...otherPaymentTypeList, ...otherPaymentDuesTypeList].map((data, index)=> 
                                  {return (
                                  <option key={index+'otherpay'} value={data.value} style={{color:"black"}}>
                                      {data.lable}
                                </option>
                                )}
                              )}
                            </AvField>
                          </Col>
                          <Col style={{marginRight:'-2rem'}}>
                            <AvField name={`otherPayment[${indexNum}].amount`} label="Amount" placeholder="0.00"
                            //onKeyUp={e=> this.calculatePaymentField(e, indexNum)}
                              onChange ={this.updateOtherPaymentField(indexNum, 'amount')}
                              //disabled={ROLENAME==='ACCOUNTANT'?true:false}  
                              value={data.amount}     
                                validate={{
                                required: {
                                    value: true,
                                    errorMessage: 'This field is required.'
                                },
                                // pattern: {
                                //   value:  /^[a-zA-Z][a-zA-Z\s]*$/,
                                //   errorMessage: `Invalid father name.`
                                // }
                                  async: function (value, ctx, input, cb) {
                                    const pattern = /^[0-9]+$/
                                      if(value && !pattern.test(value)){
                                        cb('Invalid Number')
                                      }else if(value && pattern.test(value) && !Number(value)>0){
                                        cb('Not valid amount')
                                      }else{
                                        cb(true)
                                      }
                                    }
                                }}
                            />
                            </Col>
                              <Col md={2} className='text-center' style={{marginLeft:'-1rem'}}>
                                <label style={{marginBottom:'2.5rem'}}>{" "}</label> 
                                  <button type="button" className="btn btn-sm btn-outline-light btn-rounded btn-icon" 
                                    onClick={()=>this.removeRow(indexNum, 'otherPayList')}>
                                  <i className="mdi mdi-minus-circle text-danger"></i>
                                </button>
                              </Col>
                          </Row>
                          </>
                        )
                      })}
                    <Row>
                      <Col md={1} >
                        <button type="button" className="btn btn-inverse-primary btn-rounded btn-icon" onClick={()=>this.addRow('otherPayList')}>
                          <i className="mdi mdi-plus-circle"></i>
                        </button>
                      </Col>
                    </Row>
                    <Row>
                      <Col  style={{marginRight:'-1rem'}}>
                      <AvField name={'otherPayConcession'} label="Concession" placeholder="0.00"
                          //onKeyUp={e=> this.calculatePaymentField(e, indexNum)}
                           //onChange ={this.updatePaymentField(indexNum, 'amount')}
                            //disabled={true}  
                            //value={this.state.totalOtherPayAmount}     
                            validate={{
                              async: function (value, ctx, input, cb) {
                                const pattern = /^[0-9]+$/
                                  if(value && !pattern.test(value)){
                                    cb('Invalid Number')
                                  }else{
                                    if (value && Number(value)> Number(totalOtherPayAmount)) {
                                      cb('Invalid concession')
                                    }else{
                                      cb(true)
                                    }
                                  }
                                }
                              }}
                              onKeyUp={(e)=>this.onAmountChange(e,'otherPayConcession')}
                          />
                      </Col>
                      <Col  >
                        <AvField name={'totalOtherPayAmount'} label="Total Amount" placeholder="0.00"
                          //onKeyUp={e=> this.calculatePaymentField(e, indexNum)}
                           // onChange ={this.updatePaymentField(indexNum, 'amount')}
                            disabled={true}  
                            value={this.state.totalOtherPayAmount}     
                              validate={{
                              required: {
                                  value: true,
                                  errorMessage: 'This field is required.'
                              },
                              // pattern: {
                              //   value:  /^[a-zA-Z][a-zA-Z\s]*$/,
                              //   errorMessage: `Invalid father name.`
                              // }
                                async: function (value, ctx, input, cb) {
                                  const pattern = /^[0-9]+$/
                                    if(value && !pattern.test(value)){
                                      cb('Invalid Number')
                                    }else{
                                      cb(true)
                                    }
                                  }
                              }}
                          />
                      </Col>
                    </Row>
                    <Row>
                      <Col  style={{marginRight:'-1rem'}}>
                      <AvField name={'dueOtherPay'} label="Due Amount" placeholder="0.00"
                          //onKeyUp={e=> this.calculatePaymentField(e, indexNum)}
                           //onChange ={this.updatePaymentField(indexNum, 'amount')}
                            //disabled={true}  
                            //value={this.state.totalOtherPayAmount}     
                            validate={{
                              async: function (value, ctx, input, cb) {
                                const pattern = /^[0-9]+$/
                                  if(value && !pattern.test(value)){
                                    cb('Invalid Number')
                                  }else{
                                    if (value && Number(value)> Number(totalOtherPayAmount)) {
                                      cb('Invalid due amount')
                                    }else{
                                      cb(true)
                                    }
                                  }
                                }
                              }}
                              onKeyUp={(e)=>this.onAmountChange(e,'dueOtherPay')}
                          />
                      </Col>
                      <Col  >
                        <AvField type="select" name={`dueOtherPaymentType`} label="Select Due Type"
                          //onChange ={this.updateOtherPaymentField(indexNum, 'payFor')}
                          disabled={!(this.state.dueOtherPayAmount && parseInt(this.state.dueOtherPayAmount)>0)}
                          //value={data.payFor} 
                                validate={{
                                required: {
                                    value: dueOtherPayAmount && dueOtherPayAmount>0 ? true:false,
                                    errorMessage: 'This field is required.'
                                }
                            }} >
                            <option value=''>Choose one</option>
                            {otherPaymentDuesTypeList.map((data, index)=> 
                              {return (
                              <option key={index+'dueotherpay'} value={data.value} style={{color:"black"}}>
                                  {data.lable}
                            </option>
                            )}
                            )}
                        </AvField>
                      </Col>
                    </Row>
                      {payList.map((data, indexNum)=>{
                        return (
                          <>
                          <Row>
                          <Col style={{marginRight:'-1rem'}}>
                            <AvField type="select" name={`payment[${indexNum}].payModeId`} label="Select Payment Mode"
                            onChange ={this.updatePaymentField(indexNum, 'payModeId')}
                            value={data.payModeId} 
                                  validate={{
                                  required: {
                                      value: true,
                                      errorMessage: 'This field is required.'
                                  }
                              }} >
                              <option value=''>Choose Payment Mode</option>
                              <option style={{color:"black"}} value='Cash'>Cash</option>
                              {payOptionList.map((data, index)=> 
                                  {return (
                                  <option key={index+'pay'} value={data._id} style={{color:"black"}}>
                                      {data.payMethod==='BANK'?`${data.payOptionInfo.bankName} (${data.payOptionInfo.accountNumber})` :`${data.payOptionInfo.upiType} (${data.payOptionInfo.upiId})`}
                                </option>
                                )}
                              )}
                            </AvField>
                          </Col>
                          <Col >
                            <AvField name={`payment[${indexNum}].amount`} label="Amount" placeholder="0.00"
                            //onKeyUp={e=> this.calculatePaymentField(e, indexNum)}
                              onChange ={this.updatePaymentField(indexNum, 'amount')}
                              //disabled={ROLENAME==='ACCOUNTANT'?true:false}  
                              disabled={true}
                              value={data.amount}     
                                validate={{
                                required: {
                                    value: true,
                                    errorMessage: 'This field is required.'
                                },
                                // pattern: {
                                //   value:  /^[a-zA-Z][a-zA-Z\s]*$/,
                                //   errorMessage: `Invalid father name.`
                                // }
                                  async: function (value, ctx, input, cb) {
                                    const pattern = /^[0-9]+$/
                                      if(value && !pattern.test(value)){
                                        cb('Invalid Number')
                                      }else{
                                        cb(true)
                                      }
                                    }
                                }}
                            />
                            </Col>
                              {/* <Col md={2} className='text-center'>
                                <label style={{marginBottom:'2.5rem'}}>{" "}</label> 
                                  <button type="button" className="btn btn-sm btn-outline-light btn-rounded btn-icon" 
                                    onClick={()=>this.removeRow(indexNum, 'payList')}>
                                  <i className="mdi mdi-minus-circle text-danger"></i>
                                </button>
                              </Col> */}
                          </Row>
                          </>
                        )
                      })}
                    <Row>
                      <Col style={{marginRight:'-1rem'}}>
                        <AvField name="receiptNumber" 
                          label="Receipt Number"
                          placeholder="000"
                          validate={{
                            required: {
                                value: true,
                                errorMessage: 'This field is required.'
                            },
                            }}
                        />
                      </Col>
                      <Col>
                      <AvField name="notes" label="Add Note (If required)" placeholder=" Write note here"/>
                    </Col>
                    </Row>  
                    <Row>
                        <Col className='text-center'>
                        <Button type="submit">Submit</Button>
                        </Col>
                        <Col className='text-center'>
                        <Button variant="secondary" onClick={this.closeOtherPayModal} >Cancel</Button>
                        </Col>
                    </Row>
                  </AvForm>
                </div>
              </div>
            </BlockUi>
          </Modal.Body>
        </Modal>

        <Modal
            show={this.state.deleteModalShow}
            onHide={this.handleClose}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            >
          <Modal.Header closeButton>
          <Modal.Title>Are you sure to delete this payment?</Modal.Title>
          </Modal.Header>
          <Modal.Footer>
            <Button variant="primary" onClick={()=>this.deletePaymentDetail()}>
              Ok
            </Button>
            <Button
              variant="secondary"
              onClick={this.handleClose}
            >
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal
            show={this.state.noteModal}
            onHide={this.handleCloseNoteModal}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            >
          <Modal.Header closeButton>
          <Modal.Title> 
            <small>Note Detail</small>
          </Modal.Title>
          </Modal.Header>
          <Modal.Body >
            <div className="card"> 
              <div className="card-body">
                {this.state.noteData}
              </div>
           </div>  
          </Modal.Body> 
          {/* <Modal.Footer>
            <Button variant="primary" 
            onClick={()=>this.handleClose()}>
              Ok
            </Button>
          </Modal.Footer> */}
        </Modal>
        <Modal
            show={this.state.onlinePayModal}
            onHide={this.handleCloseNoteModal}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            >
          {/* <Modal.Header closeButton>
          <Modal.Title> 
            <small>Note Detail</small>
          </Modal.Title>
          </Modal.Header> */}
          <Modal.Body >
            {/* <div className="card"> 
              <div className="card-body"> */}
              <iframe src={this.state.phonePeUrl} title="Payment" style={{ width: '100%', height: '90vh' }} />
              {/* </div>
           </div>   */}
          </Modal.Body> 
          {/* <Modal.Footer>
            <Button variant="primary" 
            onClick={()=>this.handleClose()}>
              Ok
            </Button>
          </Modal.Footer> */}
        </Modal>
        <Modal
            show={this.state.addNoteModalShow}
            onHide={this.handleClose}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            >
          <Modal.Header closeButton>
          <Modal.Title style={{marginBottom: '-2rem'}} >Add Note</Modal.Title>
          </Modal.Header>
            <Modal.Body>
              <AddNote selectedUser={this.state.selectedUser} setDataFromNote={this.data_from_note_compo}/>
            </Modal.Body> 
          <Modal.Footer style={{marginBottom: '-0.5rem', marginTop: '-1rem'}}>
          <Button className="btn btn-sm" style={{fontWeight:"100",}} 
            onClick={this.handleClose} >Close</Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

export default Payment;
